import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export class VerificationModule extends VuexModule {
    verificationCode?: string;
    /**
     * JWT to allow an email address to be verified without sending a verification email.
     * To be used by merchants to forward known/authenticated users to Okendo to write a review.
     */
    emailVerificationToken?: string;
    reviewId?: string;

    @Mutation
    UPDATE_VERIFICATION_CODE({ verificationCode }: UpdateVerificationCode) {
        this.verificationCode = verificationCode;
    }

    @Mutation
    UPDATE_EMAIL_VERIFICATION_TOKEN({ emailVerificationToken }: UpdateEmailVerificationToken) {
        this.emailVerificationToken = emailVerificationToken;
    }

    @Mutation
    UPDATE_REVIEW_ID({ reviewId }: UpdateReviewId) {
        this.reviewId = reviewId;
    }
}

export type VerificationModuleMethod = UpdateReviewId
    | UpdateEmailVerificationToken
    | UpdateVerificationCode;

interface UpdateVerificationCode {
    type: 'verification/UPDATE_VERIFICATION_CODE';
    verificationCode: string;
}

interface UpdateEmailVerificationToken {
    type: 'verification/UPDATE_EMAIL_VERIFICATION_TOKEN';
    emailVerificationToken: string;
}

interface UpdateReviewId {
    type: 'verification/UPDATE_REVIEW_ID';
    reviewId: string;
}
