import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createBlock(_component_Popper, {
    arrow: "",
    hover: _ctx.hover,
    show: _ctx.show,
    placement: "top",
    class: "c-tooltip",
    content: _ctx.content
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["hover", "show", "content"]))
}