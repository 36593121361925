
import { Options, Vue } from 'vue-class-component';

import store from '@/store';

@Options({
    props: {
        imageUrl: {
            type: String,
            default: undefined
        },
        imageClass: {
            type: String,
            default: undefined
        },
        imageWidth: {
            type: Number,
            default: undefined
        },
        imageHeight: {
            type: Number,
            default: undefined
        },
        showPlaceholderIfEmpty: {
            type: Boolean,
            default: false
        }
    },
    store
})
export default class ProductImage extends Vue {
    imageUrl?: string;
    showPlaceholderIfEmpty!: boolean;

    get isProductImageVisible(): boolean {
        return store.state.settings.settings.productImageSizingMode !== 'hidden' && (!!this.imageUrl || this.showPlaceholderIfEmpty);
    }

    get productImageSizingModeClass(): string {
        const { settings } = store.state.settings;
        return `o-productImage--${settings.productImageSizingMode ?? 'cover'}`;
    }
}
