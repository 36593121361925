import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d38c464"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-alert-content" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", {
    role: "alert",
    class: _normalizeClass(["c-alert", {
            'is-visible': _ctx.alert.isVisible,
            'c-alert--positive': _ctx.alert.status === 'success',
            'c-alert--negative': _ctx.alert.status === 'fail',
            'c-alert--warning': _ctx.alert.status === 'warning',
            'c-alert--info': _ctx.alert.status === 'info'
        }])
  }, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hide())),
      class: "c-alert-icon"
    }, [
      _createVNode(_component_icon, {
        icon: _ctx.alert.status,
        class: "c-icon--white c-icon--small"
      }, null, 8, ["icon"])
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "c-alert-content-inner",
        innerHTML: _ctx.alert.content
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hide())),
      onKeypress: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hide())),
      class: "c-alert-dismiss",
      tabindex: "0"
    }, _toDisplayString(_ctx.$t('Dismiss')), 33)
  ], 2))
}