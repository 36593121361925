import { ChannelSurvey, ChannelSurveyViewPublic, SurveySettings } from '@okendo/reviews-common';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export class SurveyModule extends VuexModule {
    channelSurveyId?: ChannelSurvey.Id;
    channelSurvey?: ChannelSurveyViewPublic;
    isLoadingSurvey = false;
    surveySettings?: SurveySettings;

    @Mutation
    UPDATE_IS_LOADING_SURVEY({ isLoadingSurvey }: UpdateIsLoadingSurvey) {
        this.isLoadingSurvey = isLoadingSurvey;
    }

    @Mutation
    UPDATE_CHANNEL_SURVEY({ channelSurvey, channelSurveyId }: UpdateChannelSurvey) {
        if (channelSurveyId && channelSurvey) {
            this.channelSurveyId = channelSurveyId;
            this.channelSurvey = channelSurvey;
        }

        this.isLoadingSurvey = false;
    }

    @Mutation
    UPDATE_SURVEY_SETTINGS({ surveySettings }: UpdateSurveySettings) {
        this.surveySettings = surveySettings;
    }
}

export type SurveyModuleMethod = UpdateIsLoadingSurvey | UpdateChannelSurvey | UpdateSurveySettings;

interface UpdateIsLoadingSurvey {
    type: 'survey/UPDATE_IS_LOADING_SURVEY';
    isLoadingSurvey: boolean;
}

interface UpdateChannelSurvey {
    type: 'survey/UPDATE_CHANNEL_SURVEY';
    channelSurvey: ChannelSurveyViewPublic | undefined;
    channelSurveyId: ChannelSurvey.Id | undefined;
}

interface UpdateSurveySettings {
    type: 'survey/UPDATE_SURVEY_SETTINGS';
    surveySettings: SurveySettings | undefined;
}
