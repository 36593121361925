
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import store from '@/store';
import { StoreMethod } from '@/store/storeTypings';
import { StyleSettings } from '@okendo/reviews-common';

@Options({
    props: { 'mode': String },
    store
})
export default class StarRatingIcon extends Vue {
    mode!: 'empty' | 'error' | 'filled';

    mounted(): void {
        this.updateIcon(this.mode);
    }

    /** Required for settings updates only */
    @Watch('starShapeType')
    onStarShapeTypeChange(): void {
        this.updateIcon(this.mode);
    }

    get iconContent(): string | undefined {
        const url = this.iconMap.get(this.mode);
        return store.state.icon.map.get(url);
    }

    get iconMap() {
        const starShapeType = this.starShapeType;

        return new Map([
            ['empty', require(`@/assets/icons/stars-${starShapeType}-empty.svg`)],
            ['error', require(`@/assets/icons/stars-${starShapeType}-error.svg`)],
            ['filled', require(`@/assets/icons/stars-${starShapeType}-filled.svg`)]
        ]);
    }

    get starShapeType(): NonNullable<StyleSettings.RecorderPlus['starShape']>['type'] {
        return store.state.settings.settings.starShape?.type ?? 'default';
    }

    updateIcon(icon: string): void {
        const url = this.iconMap.get(icon);
        store.commit<StoreMethod>({
            type: 'icon/GET_ICON',
            url
        });
    }
}
