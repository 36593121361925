
import { PropType } from '@vue/runtime-core';
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { ErrorCode } from '@/utils/errorUtils';
import Skeleton from './Skeleton.vue';

export type ViewStateStatus = 'skeleton'
    | 'error'
    | 'content'
    | 'loading'
    | 'empty';

@Options({
    components: {
        Skeleton
    },
    props: {
        buttonTitle: {
            type: String,
            default: 'Retry'
        },
        errorCode: {
            type: String,
            default: 'unknown'
        },
        emptyCaption: {
            type: String,
            default: 'No content could be found'
        },
        viewState: String as PropType<ViewStateStatus>
    },
    emits: ['retry']
})
export default class ViewState extends Vue {
    errorCode!: ErrorCode;
    errorCaption = 'Content could not be loaded';
    errorTitle = 'Something went wrong';

    @Watch('errorCode')
    onErrorCodeChange() {
        switch (this.errorCode) {
            case 'anonymous-reviews-disabled':
                this.errorTitle = `Sorry, this store doesn't allow anonymous reviews`;
                this.errorCaption = 'Please check you have the correct url address or contact the store for more details';
                break;
            case 'product-not-found':
                this.errorTitle = 'Something went wrong';
                this.errorCaption = 'We could not find the details for the product you want to review.\n Please contact the store for more information.';
                break;
            case 'review-link-expired':
                this.errorTitle = 'Sorry, this review link has expired';
                this.errorCaption = 'We are no longer accepting reviews from this link';
                break;
            default:
                this.errorTitle = 'Something went wrong';
                this.errorCaption = 'Content could not be loaded';
                break;
        }
    }
}
