import { EmailAddress, LoyaltyAPIPublic } from '@okendo/reviews-common';

import store from '@/store';
import { get, post } from './api';

const appBaseUrl = process.env.VUE_APP_API_BASE_URL;

export function getEarningRules(): Promise<LoyaltyAPIPublic.EarningRules.Get.Response> {
    const url = `${getStoreBaseUrl()}/loyalty/earning_rules`;
    return get(url);
}

export function getRedemptionRules(): Promise<LoyaltyAPIPublic.RedemptionRules.Get.Response> {
    const url = `${getStoreBaseUrl()}/loyalty/redemption_rules`;
    return get(url);
}

export function getLoyaltySettings(): Promise<LoyaltyAPIPublic.Settings.Get.Response> {
    const url = `${getStoreBaseUrl()}/loyalty/settings`;
    return get(url);
}

export function getVipTiers(): Promise<LoyaltyAPIPublic.VIPProgram.Get.Response> {
    const url = `${getStoreBaseUrl()}/loyalty/vip_program`;
    return get(url);
}

export function postLogin(reviewRequestId: string, loyaltyCustomerId: string): Promise<LoyaltyAPIPublic.Authentication.Login.Post.Response> {
    const url = `${getStoreBaseUrl()}/loyalty/login`;
    const request: LoyaltyAPIPublic.Authentication.Login.Post.Request.ByReviewRequest = {
        reviewRequestId,
        loyaltyCustomerId
    };
    return post(url, request);
}

export function postSendLoginLink(email: EmailAddress): Promise<void> {
    const url = `${getStoreBaseUrl()}/loyalty/login_link`;
    const request: LoyaltyAPIPublic.Authentication.LoginLink.Post.Request = {
        email
    };
    return post(url, request);
}

function getStoreBaseUrl(): string {
    const { subscriberId } = store.state.subscriber;
    return `${appBaseUrl}/stores/${subscriberId}`;
}
