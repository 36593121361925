import { AxiosResponse } from 'axios';

export function unauthorized(data?: { errorCode: ErrorCode }): Partial<AxiosResponse> {
    return { status: 401, data };
}

export function notFound(data?: { errorCode: ErrorCode }): Partial<AxiosResponse> {
    return { status: 404, data };
}

export function getErrorCodeFromError(unknownError: unknown): ErrorCode {
    const errors = Array.isArray(unknownError) ? unknownError : [unknownError];

    for (const error of errors) {
        if (error?.data?.errorCode) {
            return error.data.errorCode;
        }

        if (error?.status === 404) {
            return 'product-not-found';
        }
    }

    return 'unknown';
}

export type ErrorCode = 'unknown'
    | 'anonymous-reviews-disabled'
    | 'product-not-found'
    | 'review-link-expired';
