import store from '@/store';
import { StoreMethod } from '@/store/storeTypings';
import { getReferralSettings } from './api';

const cdnBaseUrl = process.env.VUE_APP_CDN_BASE_URL;

export function loadReferralsWidgetScript(): void {
    const {
        referral: { referralSettings },
        settings: { settings: recorderSettings },
        subscriber: { locale, subscriberId }
    } = store.state;

    const { email: reviewerEmail, name: reviewerName } = store.state.profile.reviewerProfile;
    window.referralDetails = {
        subscriberId,
        reviewerEmail,
        reviewerName,
        referralSettings,
        recorderSettings,
        locale: locale.code,
        variant: locale.variant,
        channel: 'post-review'
    };

    const referralsWidgetScript = document.createElement('script');
    referralsWidgetScript.setAttribute('src', `${cdnBaseUrl}/referrals/js/referrals-api.js`);
    document.head.appendChild(referralsWidgetScript);
}

export async function loadReferralSettings(): Promise<void> {
    try {
        const { settings } = await getReferralSettings();

        store.commit<StoreMethod>({
            type: 'referral/UPDATE_REFERRAL_SETTINGS',
            referralSettings: settings
        });
    }
    catch {
        // If this doesn't load before the referral widget, the widget will make the request instead
    }
}
