import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b5c5ad2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "c-poweredBy"
}
const _hoisted_2 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _directive_analytics = _resolveDirective("analytics")!
  const _directive_prevent_click_in_preview = _resolveDirective("prevent-click-in-preview")!

  return (!_ctx.hideOkendoBranding)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives(_createElementVNode("a", {
          "aria-label": _ctx.$t('Okendo Website'),
          href: "https://www.okendo.io/",
          target: "_blank",
          rel: "noreferrer"
        }, [
          _createVNode(_component_icon, {
            icon: "powered-by",
            class: "c-icon--poweredBy"
          })
        ], 8, _hoisted_2), [
          [_directive_analytics, { eventName: 'click-powered-by' }],
          [_directive_prevent_click_in_preview]
        ])
      ]))
    : _createCommentVNode("", true)
}