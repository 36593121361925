
import { Options, Vue } from 'vue-class-component';

import Popper from 'vue3-popper';

@Options({
    components: {
        Popper
    },
    props: {
        'content': String,
        'hover': {
            type: Boolean,
            default: true
        },
        'show': {
            type: Boolean,
            default: undefined
        }
    }
})
export default class Tooltip extends Vue {

}
