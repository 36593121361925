
import { Options, Vue } from 'vue-class-component';

import ReviewForm from './components/ReviewForm.vue';
import Banner from '@/shared-components/Banner.vue';
import ViewState, { ViewStateStatus } from '@/shared-components/ViewState.vue';
import store from '@/store';
import { verifyProductId } from '@/utils/productUtils';
import { postAnalytics, postErrorAnalytics } from '@/utils/api';

@Options({
    components: {
        ReviewForm,
        Banner,
        ViewState
    },
    store
})
export default class Review extends Vue {
    viewState: ViewStateStatus = 'loading';

    mounted(): void {
        window.scrollTo(0, 0);
        postAnalytics({ eventName: 'page-open' });
        this.loadReviewData();
    }

    async loadReviewData(): Promise<void> {
        this.viewState = 'loading';
        try {
            await verifyProductId(this.$route.query.productId);
            this.viewState = 'content';
        }
        catch (error) {
            postErrorAnalytics(error);
            this.viewState = 'error';
        }
    }
}
