import { LocationQuery } from 'vue-router';

export function resolveState<S>(state: (() => S) | S) {
    const resolvedState: S = state instanceof Function ? state() : state;
    if (!state) {
        throw new Error(
            'Attempting to access a state before it is initialised'
        );
    }
    return resolvedState!;
}

export function escapeTags(string: string): string {
    return string.replace(/[<>]/g, tag =>
        tag === '<' ? '&lt;'
            : tag === '>' ? '&gt;'
                : tag);
}

export function formatQueryStrings(queryRecords: LocationQuery): QueryStrings {
    const queryStrings: QueryStrings = {};
    for (const key in queryRecords) {
        const value = queryRecords[key];

        if (Array.isArray(value)) {
            queryStrings[key] = value.filter((i): i is string => typeof i === 'string')[0];
        }
        else if (value) {
            queryStrings[key] = value;
        }
    }

    return queryStrings;
}

export interface QueryStrings {
    [key: string]: string;
}
