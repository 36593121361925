import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8756ac64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-form-row" }
const _hoisted_2 = { class: "c-form-col u-marginLeftUI--small" }
const _hoisted_3 = { class: "u-fontWeight--bold u-marginBottomUI--xxSmall u-fontSize-vue--medium" }
const _hoisted_4 = {
  key: 0,
  class: "c-ratingErrorMessage"
}
const _hoisted_5 = {
  class: "c-labelledFormBox-label",
  for: "title"
}
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = {
  class: "c-labelledFormBox-label",
  for: "description"
}
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = {
  key: 0,
  class: "u-marginBottomUI--large"
}
const _hoisted_10 = { class: "u-marginBottomUI--xxxLarge" }
const _hoisted_11 = { class: "u-marginBottomUI--xxxLarge" }
const _hoisted_12 = { class: "c-form-row u-marginBottomUI--xSmall" }
const _hoisted_13 = ["aria-label"]
const _hoisted_14 = {
  key: 0,
  class: "c-loader c-loader--medium c-loader--buttonFontColor"
}
const _hoisted_15 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_image = _resolveComponent("product-image")!
  const _component_star_rating = _resolveComponent("star-rating")!
  const _component_media_uploader = _resolveComponent("media-uploader")!
  const _component_profile = _resolveComponent("profile")!
  const _component_attribute = _resolveComponent("attribute")!
  const _component_terms = _resolveComponent("terms")!
  const _component_powered_by_okendo = _resolveComponent("powered-by-okendo")!
  const _directive_analytics = _resolveDirective("analytics")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitReviewForm && _ctx.submitReviewForm(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({
            'u-marginBottomUI--large': _ctx.isRatingInvalid,
            'u-marginBottomUI--xSmall': !_ctx.isRatingInvalid
        })
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_product_image, {
          imageUrl: _ctx.product.imageUrl,
          imageWidth: 80,
          imageHeight: 80,
          showPlaceholderIfEmpty: true,
          class: "c-form-col"
        }, null, 8, ["imageUrl"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.product.name), 1),
          _createVNode(_component_star_rating, {
            modelValue: _ctx.rating,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.rating) = $event)),
            hasError: _ctx.isRatingInvalid
          }, null, 8, ["modelValue", "hasError"]),
          (_ctx.isRatingInvalid)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('Please enter a rating')), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([{ hasError: !_ctx.title && _ctx.hasSubmitted }, "c-labelledFormBox u-marginBottomUI--xSmall"])
    }, [
      _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('Review Title')), 1),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.title) = $event)),
        placeholder: _ctx.titlePlaceholderText,
        class: "c-labelledFormBox-input",
        id: "title",
        type: "text",
        maxlength: "1000"
      }, null, 8, _hoisted_6), [
        [_vModelText, _ctx.title],
        [_directive_analytics, {
                    eventName: 'change-review-title',
                    label: 'titleLength'
                }, "change"]
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([{ hasError: !_ctx.body && _ctx.hasSubmitted }, "c-labelledFormBox u-marginBottomUI--xSmall"])
    }, [
      _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('Review Description')), 1),
      _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.body) = $event)),
        placeholder: _ctx.bodyPlaceholderText,
        class: "c-textarea c-labelledFormBox-input",
        id: "description",
        maxlength: "10000"
      }, null, 8, _hoisted_8), [
        [_vModelText, _ctx.body],
        [_directive_analytics, {
                    eventName: 'change-review-body',
                    label: 'bodyLength'
                }, "change"]
      ])
    ], 2),
    (_ctx.isMediaCaptureEnabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_media_uploader, {
            modelValue: _ctx.mediaBlocks,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.mediaBlocks) = $event)),
            class: "u-marginBottomUI--large"
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("fieldset", _hoisted_10, [
      _createVNode(_component_profile, { hasSubmitted: _ctx.hasSubmitted }, null, 8, ["hasSubmitted"])
    ]),
    _createElementVNode("div", _hoisted_11, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedAttributes, (attribute) => {
        return _withDirectives((_openBlock(), _createBlock(_component_attribute, {
          key: attribute.profileQuestionId || attribute.attributeId,
          modelValue: _ctx.attributeValues[attribute.profileQuestionId || attribute.attributeId],
          "onUpdate:modelValue": ($event: any) => ((_ctx.attributeValues[attribute.profileQuestionId || attribute.attributeId]) = $event),
          attribute: attribute,
          countryCode: _ctx.isZone(attribute) ? _ctx.countryCode : undefined,
          hasError: _ctx.isRequiredAttributeEmpty(attribute) && _ctx.hasSubmitted,
          class: "u-marginBottomUI--xLarge"
        }, null, 8, ["modelValue", "onUpdate:modelValue", "attribute", "countryCode", "hasError"])), [
          [_directive_analytics, {
                        eventName: 'change-attribute',
                        label: 'attributeTitle',
                        value: attribute.title
                    }, "change"]
        ])
      }), 128))
    ]),
    _createVNode(_component_terms),
    _createElementVNode("div", _hoisted_12, [
      _withDirectives(_createElementVNode("button", {
        "aria-label": _ctx.$t('Submit'),
        class: "c-button"
      }, [
        (_ctx.isSending)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14))
          : (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.$t(_ctx.isOptedIn ? 'Submit' : 'Agree & Submit')), 1))
      ], 8, _hoisted_13), [
        [_directive_analytics, { eventName: 'click-submit-review' }]
      ])
    ]),
    _createVNode(_component_powered_by_okendo)
  ], 32))
}