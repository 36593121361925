import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e7f6abb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-dateSelect" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["value"]
const _hoisted_6 = ["for"]
const _hoisted_7 = ["id"]
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["value"]
const _hoisted_10 = ["for"]
const _hoisted_11 = ["id"]
const _hoisted_12 = ["disabled"]
const _hoisted_13 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.allowDaySelect)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("label", {
            for: `${_ctx.attributeName}-day`,
            class: "u-assistive-hidden"
          }, _toDisplayString(_ctx.$t('Day')), 9, _hoisted_2),
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelDay) = $event)),
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDateChange())),
            id: `${_ctx.attributeName}-day`,
            class: _normalizeClass([{ 'isEmpty': !_ctx.modelDay }, "c-select c-dateSelect-select"])
          }, [
            _createElementVNode("option", {
              value: null,
              disabled: !_ctx.isOptional
            }, _toDisplayString(_ctx.$t('Day')), 9, _hoisted_4),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dayOptions, (option) => {
              return (_openBlock(), _createElementBlock("option", {
                key: option,
                value: option
              }, _toDisplayString(option), 9, _hoisted_5))
            }), 128))
          ], 42, _hoisted_3), [
            [_vModelSelect, _ctx.modelDay]
          ])
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.allowMonthSelect)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("label", {
            for: `${_ctx.attributeName}-month`,
            class: "u-assistive-hidden"
          }, _toDisplayString(_ctx.$t('Month')), 9, _hoisted_6),
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelMonth) = $event)),
            onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onDateChange())),
            id: `${_ctx.attributeName}-month`,
            class: _normalizeClass([{ 'isEmpty': !_ctx.modelMonth }, "c-select c-dateSelect-select"])
          }, [
            _createElementVNode("option", {
              value: null,
              disabled: !_ctx.isOptional
            }, _toDisplayString(_ctx.$t('Month')), 9, _hoisted_8),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monthOptions, (option, index) => {
              return (_openBlock(), _createElementBlock("option", {
                key: option,
                value: index + 1
              }, _toDisplayString(_ctx.$t(option)), 9, _hoisted_9))
            }), 128))
          ], 42, _hoisted_7), [
            [_vModelSelect, _ctx.modelMonth]
          ])
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.allowYearSelect)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createElementVNode("label", {
            for: `${_ctx.attributeName}-year`,
            class: "u-assistive-hidden"
          }, _toDisplayString(_ctx.$t('Year')), 9, _hoisted_10),
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modelYear) = $event)),
            onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onDateChange())),
            id: `${_ctx.attributeName}-year`,
            class: _normalizeClass([{ 'isEmpty': !_ctx.modelYear }, "c-select c-dateSelect-select"])
          }, [
            _createElementVNode("option", {
              value: null,
              disabled: !_ctx.isOptional
            }, _toDisplayString(_ctx.$t('Year')), 9, _hoisted_12),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.yearOptions, (option) => {
              return (_openBlock(), _createElementBlock("option", {
                key: option,
                value: option
              }, _toDisplayString(option), 9, _hoisted_13))
            }), 128))
          ], 42, _hoisted_11), [
            [_vModelSelect, _ctx.modelYear]
          ])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}