import { Subscriber } from '@okendo/reviews-common';
import { english } from '@okendo/translations-common';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export class SubscriberModule extends VuexModule {
    avatarImageUrl = '';
    allowAnonymous = true;
    currencySymbol = '$';
    previewMode = false;
    storeName = 'Leave a Review';
    storeUrl = '';
    subscriberId: Subscriber.Id = '';
    locale: Subscriber.LocaleAndVariant = english;

    @Mutation
    UPDATE_SUBSCRIBER_ID({ subscriberId }: UpdateSubscriberId) {
        this.subscriberId = subscriberId;
    }

    @Mutation
    UPDATE_STORE_NAME({ storeName }: UpdateStoreName) {
        this.storeName = storeName;
    }

    @Mutation
    UPDATE_AVATAR_URL({ avatarImageUrl }: UpdateAvatarImageUrl) {
        this.avatarImageUrl = avatarImageUrl;
    }

    @Mutation
    UPDATE_ALLOW_ANONYMOUS({ allowAnonymous }: UpdateAllowAnonymous) {
        this.allowAnonymous = allowAnonymous;
    }

    @Mutation
    UPDATE_PREVIEW_MODE({ previewMode }: UpdatePreviewMode) {
        this.previewMode = previewMode;
    }

    @Mutation
    UPDATE_STORE_URL({ storeUrl }: UpdateStoreUrl) {
        this.storeUrl = storeUrl;
    }

    @Mutation
    UPDATE_CURRENCY_SYMBOL({ currencySymbol }: UpdateCurrencySymbol) {
        this.currencySymbol = currencySymbol;
    }

    @Mutation
    UPDATE_LOCALE({ locale }: UpdateLocale) {
        this.locale = locale;
    }
}

export type SubscriberModuleMethod = UpdateSubscriberId
    | UpdateStoreName
    | UpdateAvatarImageUrl
    | UpdatePreviewMode
    | UpdateAllowAnonymous
    | UpdateStoreUrl
    | UpdateCurrencySymbol
    | UpdateLocale;

type ModulePrefix = 'subscriber';

interface UpdateSubscriberId {
    type: `${ModulePrefix}/UPDATE_SUBSCRIBER_ID`;
    subscriberId: Subscriber.Id;
}

interface UpdateStoreName {
    type: `${ModulePrefix}/UPDATE_STORE_NAME`;
    storeName: string;
}

interface UpdateAvatarImageUrl {
    type: `${ModulePrefix}/UPDATE_AVATAR_URL`;
    avatarImageUrl: string;
}

interface UpdateAllowAnonymous {
    type: `${ModulePrefix}/UPDATE_ALLOW_ANONYMOUS`;
    allowAnonymous: boolean;
}

interface UpdatePreviewMode {
    type: `${ModulePrefix}/UPDATE_PREVIEW_MODE`;
    previewMode: boolean;
}

interface UpdateStoreUrl {
    type: 'subscriber/UPDATE_STORE_URL';
    storeUrl: string;
}

interface UpdateCurrencySymbol {
    type: 'subscriber/UPDATE_CURRENCY_SYMBOL';
    currencySymbol: string;
}

interface UpdateLocale {
    type: 'subscriber/UPDATE_LOCALE';
    locale: Subscriber.LocaleAndVariant;
}
