import { PostReviewChannelSurvey, Product, ReferralsAPIPublic, Review, ReviewResource, ReviewsAPIPublic, SKO, Subscriber, SurveysAPIPublic, UCVAPI } from '@okendo/reviews-common';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import qs from 'qs';

import store from '@/store';
import router from '@/router/index';
import { AnalyticsEvent } from './analyticsUtil';
import { isMobile } from './deviceUtils';
import { formatQueryStrings } from './vueUtils';
import { isTestProduct } from './previewModeUtil';
import { isQuestionRoute } from './routerUtils';

const BASE_URL = process.env.VUE_APP_API_BASE_URL;
const INPUT_BUCKET = process.env.VUE_APP_PROCESSING_INPUT_BUCKET;
const SAMPLE_DATA_HEADER = {
    headers: {
        'X-Okendo-Force-Sample-Data': 'true'
    }
};
const PREVIEW_MODE_HEADER = {
    headers: {
        'X-Okendo-Preview-Mode': 'true'
    }
};

export function getProduct(productId: Product.Id): Promise<ReviewsAPIPublic.Products.ProductId.Get.Response> {
    const previewMode = store.state.subscriber.previewMode;
    if (previewMode && isTestProduct(productId)) {
        const url = `${BASE_URL}/stores/sample/products/sample`;
        return get(url, SAMPLE_DATA_HEADER);
    }

    const url = `${BASE_URL}/stores/${getSubscriberId()}/products/${productId}`;
    return get(url, getCacheBusterHeader());
}

export function getReviewRequestDetails(reviewRequestId: string): Promise<ReviewsAPIPublic.ReviewRequest.ReviewRequestId.Get.Response> {
    const url = `${BASE_URL}/stores/${getSubscriberId()}/review_request/${reviewRequestId}`;
    const previewMode = store.state.subscriber.previewMode;
    if (previewMode) {
        return get(url, SAMPLE_DATA_HEADER);
    }

    return get(url, getCacheBusterHeader());
}

export function getRewardPrompt(reviewRequestId: string, data: ReviewsAPIPublic.ReviewRequest.ReviewRequestId.RewardPrompt.Post.Request): Promise<ReviewsAPIPublic.ReviewRequest.ReviewRequestId.RewardPrompt.Post.Response> {
    const url = `${BASE_URL}/stores/${getSubscriberId()}/review_request/${reviewRequestId}/reward_prompt`;
    return post(url, data);
}

export function getSettings(): Promise<ReviewsAPIPublic.ReviewCaptureSettings.Get.Response> {
    const url = `${BASE_URL}/stores/${getSubscriberId()}/review_capture_settings`;
    return get(url, getCacheBusterHeader());
}

export function getProfileQuestionAnswers(params: UCVAPI.ProfileQuestionAnswers.Get.Request): Promise<UCVAPI.ProfileQuestionAnswers.Get.Response> {
    const url = `${BASE_URL}/stores/${getSubscriberId()}/profile_question_answers`;
    return get(url, { params });
}

export function getReferralSettings(): Promise<ReferralsAPIPublic.Settings.Get.Response> {
    const url = `${BASE_URL}/stores/${getSubscriberId()}/referral_settings`;
    return get(url);
}

export function getChannelSurveyConfiguration(reviewDetails?: PostReviewChannelSurvey.ReviewDetails): Promise<SurveysAPIPublic.ChannelConfiguration.Post.Response> {
    const url = `${BASE_URL}/stores/${getSubscriberId()}/channel_configuration`;
    const previewMode = store.state.subscriber.previewMode;
    if (previewMode) {
        return post(url, { channel: 'post-review' }, PREVIEW_MODE_HEADER);
    }

    return post(url, { channel: 'post-review', reviewDetails });
}

export function getSurveySettings(): Promise<SurveysAPIPublic.Settings.Get.Response> {
    const url = `${BASE_URL}/stores/${getSubscriberId()}/survey_settings`;
    return get(url);
}

export function getTopReviewedProducts(): Promise<ReviewsAPIPublic.ProductsTop.Get.Response> {
    const url = `${BASE_URL}/stores/${getSubscriberId()}/products_top`;
    const previewMode = store.state.subscriber.previewMode;
    if (previewMode) {
        return get(url, SAMPLE_DATA_HEADER);
    }
    return get(url);
}

export function postErrorAnalytics(error: any): void {
    const value = error?.message ?? JSON.stringify({ ...error });

    postAnalytics({
        eventName: 'error',
        value,
        label: 'errorMessage'
    });
}

export function postAnalytics({ eventName, value, label }: AnalyticsEvent): void {
    const route = router.currentRoute.value;
    const queryStrings = formatQueryStrings(route.query);
    const { subscriberId, productId, reviewRequestId, elementId, previewMode, testMode } = queryStrings;

    if (!subscriberId || previewMode || testMode) {
        return;
    }

    const { sessionId } = store.state.profile;

    const appName = isQuestionRoute() ? 'question-recorder' : 'recorder';
    const url = `${BASE_URL}/stores/${subscriberId}/analytics_events`;
    const analyticsData: SKO = {
        pageName: route.name,
        isMobile: isMobile()
    };

    if (label) {
        analyticsData[label] = value ?? 'undefined';
    }

    const data: ReviewsAPIPublic.AnalyticsEvents.Post.Request = {
        analyticsEvent: {
            appName,
            data: analyticsData,
            eventName,
            productId,
            reviewRequestId,
            sequenceElementId: elementId,
            sessionId
        }
    };

    sendBeacon(url, data);
}

export function getReviewById(reviewRequestId: string, reviewId: Review.Id): Promise<ReviewsAPIPublic.ReviewRequest.ReviewRequestId.Review.ReviewId.Get.Response> {
    const url = `${BASE_URL}/stores/${getSubscriberId()}/review_request/${reviewRequestId}/review/${reviewId}`;
    return get(url, getCacheBusterHeader());
}

export function postSiteReview(siteReviewToPost: ReviewResource.CreateSiteReview.Review): Promise<void> {
    const url = `${BASE_URL}/stores/${getSubscriberId()}/site_reviews`;
    return post(url, { review: siteReviewToPost });
}

export function postMedia(mediaToPost: FormData, callback: ProgressHandler): Promise<void> {
    const url = `https://${INPUT_BUCKET}.s3.amazonaws.com`;
    const config = {
        onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            callback(percentCompleted);
        },
        headers: { 'Content-Type': 'multipart/form-data' }
    };

    return post(url, mediaToPost, config);
}

export function postReview(productId: Product.Id, reviewToPost: ReviewResource.CreateReview.Review): Promise<ReviewsAPIPublic.Products.ProductId.Reviews.Post.Response> {
    const url = `${BASE_URL}/stores/${getSubscriberId()}/products/${productId}/reviews`;
    return post(url, reviewToPost);
}

export function postQuestion(questionToPost: ReviewsAPIPublic.Questions.Post.Request): Promise<ReviewsAPIPublic.Questions.Post.Response> {
    const url = `${BASE_URL}/stores/${getSubscriberId()}/questions`;
    return post(url, questionToPost);
}

export function updateReview(reviewId: Review.Id, reviewToUpdate: ReviewResource.UpdateReview.Review): Promise<ReviewsAPIPublic.Reviews.ReviewId.Post.Response> {
    const url = `${BASE_URL}/stores/${getSubscriberId()}/reviews/${reviewId}`;
    return post(url, { review: reviewToUpdate });
}

export function postEmailVerification(reviewId: string, verificationCode: string): Promise<void> {
    const verifyUrl = `${BASE_URL}/stores/${getSubscriberId()}/reviews/${reviewId}/verify`;
    return post(verifyUrl, { verificationCode });
}

export function postRating(productId: Product.Id, ratingToPost: ReviewsAPIPublic.Products.ProductId.Rate.Request): Promise<ReviewsAPIPublic.Products.ProductId.Rate.Response> {
    const url = `${BASE_URL}/stores/${getSubscriberId()}/products/${productId}/rate`;
    return post(url, ratingToPost);
}

export async function get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const res = await axios.get(url, {
        ...config,
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
    })
        .catch(handleError);

    return res.data;
}

export async function put<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
    const res = await axios.put(url, data, config)
        .catch(handleError);

    return res.data;
}

export async function post<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
    const res = await axios.post(url, data, config)
        .catch(handleError);

    return res.data;
}

function sendBeacon(url: string, data: any): void {
    try {
        const successfullyQueued = navigator.sendBeacon(url, JSON.stringify(data));
        if (!successfullyQueued) {
            throw Error('Data limit exceeded, attempting synchronous API call');
        }
    }
    catch {
        post(url, data);
    }
}

function handleError(error: AxiosError<APIError>): any {
    if (process.env.NODE_ENV !== 'production') {
        console.log('API Error', { ...error });
    }

    throw error.response;
}

function getSubscriberId(): Subscriber.Id {
    return store.state.subscriber.subscriberId;
}

function getCacheBusterHeader(): AxiosRequestConfig {
    return {
        headers: {
            'X-Okendo-Timestamp': Date.now()
        }
    };
}

export interface APIError {
    error: {
        description: string;
        code?: string;
        context?: { [x: string]: unknown; };
    };
    statusCode?: number;
}
