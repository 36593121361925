import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

import './assets/styles/main.scss';
import App from './App.vue';
import router from './router';
import store from './store';
import Analytics from './utils/analyticsUtil';
import i18n from './i18n';
import { configureRaygun, sendToRaygun } from './raygun';
import PreviewClickPrevent from './utils/previewModeUtil';
import { getRewardCurrencyWithValue } from './utils/rewardUtils';

configureRaygun();

const app = createApp(App)
    .use(i18n)
    .use(store)
    .use(router)
    .use(VueAxios, axios)
    .directive('analytics', Analytics)
    .directive('prevent-click-in-preview', PreviewClickPrevent);

app.config.globalProperties.$getRewardCurrencyWithValue = getRewardCurrencyWithValue;

app.config.errorHandler = (err, vm, info) => {
    if (process.env.NODE_ENV !== 'production') {
        console.error('App Error', err);
    }

    sendToRaygun((err as Error), { vm, info });
};

app.mount('#app');
