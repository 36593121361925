import { Module, MutationAction, VuexModule } from 'vuex-module-decorators';
import { uuid } from 'vue-uuid';

import { resolveState } from '@/utils/vueUtils';

@Module({ namespaced: true })
export class AlertModule extends VuexModule<AlertModule> {
    content: string | undefined;
    status: AlertStatus = 'success';
    isVisible = false;
    currentAlertId?: string;

    @MutationAction({ mutate: ['isVisible'] })
    async SHOW({ alertData }: Show) {
        const state = resolveState(this.state);
        state.status = alertData.status;
        state.content = alertData.content;
        state.isVisible = true;

        const alertId = uuid.v4();
        state.currentAlertId = alertId;

        return new Promise(resolve => setTimeout(resolve, 3000))
            .then(() => ({ isVisible: state.currentAlertId !== alertId }));
    }

    @MutationAction({ mutate: ['isVisible'] })
    async HIDE() {
        return { isVisible: false };
    }
}

export type AlertModuleMethod = Show | Hide;

type ModulePrefix = 'alert';

interface Show {
    type: `${ModulePrefix}/SHOW`;
    alertData: {
        content: string;
        status: AlertStatus;
    };
}

interface Hide {
    type: `${ModulePrefix}/HIDE`;
}

export interface AlertData {
    status: AlertStatus;
    content: string;
}

type AlertStatus = 'success' | 'fail' | 'warning' | 'info';
