
import { Options, Vue } from 'vue-class-component';

import StarRatingIcon from '@/shared-components/StarRatingIcon.vue';

@Options({
    components: {
        StarRatingIcon
    },
    props: {
        modelValue: String,
        hasError: Boolean,
        ratingReadonly: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'normal',
            validator: (status: string) => ['normal', 'small', 'xSmall'].includes(status)
        }
    },
    emits: {
        'update:modelValue': (v: string) => typeof v === 'string'
    }
})
export default class StarRating extends Vue {
    starRatingName = `rating-${Math.floor(Math.random() * 1000000)}`;
    modelValue!: string;
    ratingReadonly!: boolean;

    get tabIndex(): number {
        return this.ratingReadonly ? -1 : 0;
    }

    get visibleStarsWidth(): string {
        return `${parseFloat(this.modelValue) * 20}%`;
    }

    updateRating(value: string): void {
        this.$emit('update:modelValue', value);
    }
}
