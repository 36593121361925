import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18c20151"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "c-notificationBar"
}
const _hoisted_2 = { class: "c-notificationBar-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_ctx.isOpen)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = false)),
          class: "c-button c-notificationBar-close"
        }, [
          _createVNode(_component_icon, {
            icon: "close",
            class: "c-icon--closeNotificationBar"
          })
        ])
      ]))
    : _createCommentVNode("", true)
}