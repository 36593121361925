import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e2a6cac"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _directive_analytics = _resolveDirective("analytics")!
  const _directive_prevent_click_in_preview = _resolveDirective("prevent-click-in-preview")!

  return (!_ctx.isOptedIn)
    ? (_openBlock(), _createBlock(_component_i18n_t, {
        key: 0,
        keypath: _ctx.keypath,
        tag: "div",
        class: "c-terms u-marginBottomUI--xSmall"
      }, {
        termsAndConditions: _withCtx(() => [
          _withDirectives(_createElementVNode("a", {
            href: _ctx.termsUrl,
            target: "_blank",
            rel: "noreferrer"
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t('Terms and Conditions')), 1)
          ], 8, _hoisted_1), [
            [_directive_analytics, { eventName: 'click-terms' }],
            [_directive_prevent_click_in_preview]
          ])
        ]),
        privacyPolicy: _withCtx(() => [
          _withDirectives(_createElementVNode("a", {
            href: _ctx.privacyUrl,
            target: "_blank",
            rel: "noreferrer"
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t('Privacy Policy')), 1)
          ], 8, _hoisted_2), [
            [_directive_analytics, { eventName: 'click-privacy-policy' }],
            [_directive_prevent_click_in_preview]
          ])
        ]),
        _: 1
      }, 8, ["keypath"]))
    : _createCommentVNode("", true)
}