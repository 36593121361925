
import store from '@/store';
import { Options, Vue } from 'vue-class-component';

import Icon from '@/shared-components/Icon.vue';
import { StoreMethod } from '@/store/storeTypings';

@Options({
    components: {
        Icon
    },
    store
})
export default class Alert extends Vue {
    timeoutId = 0;

    get alert() {
        return store.state.alert;
    }

    hide(): void {
        store.dispatch<StoreMethod>({ type: 'alert/HIDE' });
    }
}
