
import { Options, Vue } from 'vue-class-component';

import Icon from '@/shared-components/Icon.vue';

@Options({
    components: {
        Icon
    },
    props: {
        isModalVisible: Boolean,
        okeClass: String
    },
    emits: {
        'response': (v: BaseModalResponse) => typeof v === 'object'
    }
})
export default class Modal extends Vue {
    onModalClose(response?: BaseModalResponse): void {
        this.$emit('response', response);
    }
}

export interface BaseModalResponse {
    confirmation: boolean;
}
