import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "c-rangeInput" }
const _hoisted_2 = { class: "c-rangeInput-container" }
const _hoisted_3 = ["id", "name", "checked", "value", "aria-label"]
const _hoisted_4 = ["for"]
const _hoisted_5 = {
  key: 0,
  class: "c-rangeInput-centreDot"
}
const _hoisted_6 = { class: "u-display--flex" }
const _hoisted_7 = { class: "c-rangeInput-displayLabel c-rangeInput-displayLabel--third" }
const _hoisted_8 = { class: "c-rangeInput-displayLabel c-rangeInput-displayLabel--third" }
const _hoisted_9 = { class: "c-rangeInput-displayLabel c-rangeInput-displayLabel--third" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          _createElementVNode("input", {
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateModelValue($event.target.value))),
            id: `${_ctx.attributeName}-${index}`,
            name: _ctx.attributeName,
            checked: _ctx.modelValue === index,
            value: index,
            "aria-label": index,
            class: "c-rangeInput-input u-assistive-hidden",
            type: "radio"
          }, null, 40, _hoisted_3),
          _createElementVNode("label", {
            for: `${_ctx.attributeName}-${index}`,
            class: "c-rangeInput-label",
            role: "presentation"
          }, [
            _createVNode(_component_icon, {
              icon: "success",
              class: "c-icon--centredRange"
            }),
            (index === 3)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5))
              : _createCommentVNode("", true)
          ], 8, _hoisted_4)
        ], 64))
      }), 64))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.minLabel), 1),
      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.midLabel), 1),
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.maxLabel), 1)
    ])
  ]))
}