import { createI18n } from 'vue-i18n';

const en = require('@/locales/en.json').default;

export default createI18n({
    legacy: false,
    globalInjection: true,
    locale: 'en',
    fallbackLocale: 'en',
    messages: { en }
});
