import { nextTick } from 'vue';

import i18n from '@/i18n';
import store from '@/store';
import { StoreMethod } from '@/store/storeTypings';
import { postAnalytics } from '@/utils/api';

export function validateRules(rules: Record<string, boolean>, invalidAttributes?: string[]): boolean {
    const invalidFields = Object.entries(rules).reduce((acc, field) => {
        const [fieldName, isFieldValid] = field;
        if (!isFieldValid) {
            acc.push(fieldName);
        }
        return acc;
    }, [] as string[]);
    invalidFields.push(...(invalidAttributes || []));

    const isValid = !invalidFields.length;

    if (!isValid) {
        postAnalytics({
            eventName: 'error-submitting-review',
            label: 'invalidFields',
            value: invalidFields
        });

        store.dispatch<StoreMethod>({
            type: 'alert/SHOW',
            alertData: {
                content: getInvalidMessage(invalidFields),
                status: 'fail'
            }
        });
    }

    return isValid;
}

export function scrollToFirstErrorElement(): void {
    nextTick(() => {
        const element = document.getElementsByClassName('hasError')?.[0];
        if (element && element instanceof HTMLElement) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            const elementToFocus =
                element.nodeName.toLowerCase() === 'input'
                    ? element
                    : element.querySelector('input');
            elementToFocus?.focus({ preventScroll: true });
        }
    });
}

function getInvalidMessage(invalidFields: string[]): string {
    if (!invalidFields.length) {
        return '';
    }

    const messagePrefix = `<strong>${i18n.global.t('There was a problem submitting your review')}</strong>`;
    if (invalidFields.length > 2) {
        return i18n.global.t(
            '{messagePrefix}\nPlease correct the <strong>{errorsCount} marked errors</strong> and try again',
            {
                messagePrefix,
                errorsCount: invalidFields.length
            }
        );
    }

    const translatedFields = invalidFields.map(field => i18n.global.t(field));
    return `${messagePrefix}\n${translatedFields.join('\n')}`;
}

export function isEmailAddress(s: string | undefined): boolean {
    // regex is from common-node, to remain consistent with the API
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const trimmedEmail = s?.trim();
    return !!trimmedEmail && emailRegex.test(trimmedEmail);
}
