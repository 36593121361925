import { ProductRecorderView } from '@okendo/reviews-common';

let countries: Country[] = [];
let countryZones: CountryZone[] = [];

export async function checkForLocationAttributes(product: ProductRecorderView): Promise<void> {
    const locationProfileQuestion = product.attributes?.find(attribute => attribute.type === 'location');
    if (locationProfileQuestion && locationProfileQuestion.type === 'location') {
        await importCountries();
        if (locationProfileQuestion.components === 'country-and-zone') {
            await importCountryZones();
        }
        return;
    }

    if (product.hasCountryAttribute && !countries.length) {
        await importCountries();
    }

    if (product.hasZoneAttribute && !countryZones.length) {
        await importCountryZones();
    }
}

async function importCountries(): Promise<void> {
    const { default: countriesJson } = await import(
        /* webpackChunkName: "countries" */ `../countries/countries.json`
    );
    countries = countriesJson.countries;
}

async function importCountryZones(): Promise<void> {
    const { default: countryZonesJson } = await import(
        /* webpackChunkName: "country-zones" */ `../countries/countryZones.json`
    );
    countryZones = countryZonesJson.countryZones;
}

export function getCountries(): Country[] {
    return countries;
}

export function getCountryZones(): CountryZone[] {
    return countryZones;
}

export interface CountryZone {
    countryCode: string;
    zoneDisplayName: string;
    zones: Zone[];
}

export interface Zone {
    displayName: string;
    value: string;
}

export interface Country {
    displayName: string;
    value: string;
}
