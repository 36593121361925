import { ReviewEditToken, ReviewRequestResource, ReviewsAPIPublic } from '@okendo/reviews-common';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export class ReviewModule extends VuexModule {
    review?: ReviewRequestResource.GetReview.Review;
    reviewerName?: string;
    reviewed?: ReviewsAPIPublic.ReviewRequest.ReviewRequestId.Get.Response.Reviewed[];
    reviewEnhanced?: boolean;
    editToken?: ReviewEditToken.Token;
    videoThumbnails: ReviewRequestResource.GetReview.Media[] = [];

    @Mutation
    UPDATE_REVIEW({ review, reviewerName, reviewed, reviewEnhanced }: UpdateReview) {
        this.review = review;
        this.reviewerName = reviewerName;
        this.reviewed = reviewed;
        this.reviewEnhanced = reviewEnhanced;
    }

    @Mutation
    UPDATE_EDIT_TOKEN({ editToken }: UpdateEditToken) {
        this.editToken = editToken;
    }

    @Mutation
    UPDATE_VIDEO_THUMBNAILS({ videoThumbnails }: UpdateVideoThumbnails) {
        this.videoThumbnails = videoThumbnails;
    }

    @Mutation
    CLEAR_REVIEW() {
        this.review = undefined;
        this.videoThumbnails = [];
    }
}

export type ReviewModuleMethod = UpdateReview
    | UpdateVideoThumbnails
    | UpdateEditToken
    | ClearReview;

type ModulePrefix = 'review';

interface UpdateReview {
    type: `${ModulePrefix}/UPDATE_REVIEW`;
    review: ReviewRequestResource.GetReview.Review;
    reviewerName?: string;
    reviewed?: ReviewsAPIPublic.ReviewRequest.ReviewRequestId.Get.Response.Reviewed[],
    reviewEnhanced?: boolean;
}

interface UpdateEditToken {
    type: `${ModulePrefix}/UPDATE_EDIT_TOKEN`;
    editToken: ReviewEditToken.Token | undefined;
}

interface UpdateVideoThumbnails {
    type: `${ModulePrefix}/UPDATE_VIDEO_THUMBNAILS`;
    videoThumbnails: ReviewRequestResource.GetReview.Media[];
}

interface ClearReview {
    type: `${ModulePrefix}/CLEAR_REVIEW`;
}
