
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        modelValue: String,
        minLabel: String,
        maxLabel: String,
        rangeLength: {
            type: Number,
            default: 5
        }
    },
    emits: {
        'update:modelValue': (v: string) => typeof v === 'string'
    }
})
export default class RangeInput extends Vue {
    attributeName = `range-${Math.floor(Math.random() * 1000000)}`;

    updateModelValue(value: string): void {
        this.$emit('update:modelValue', value);
    }
}
