
import { Options, Vue } from 'vue-class-component';

import Icon from './Icon.vue';

@Options({
    components: {
        Icon
    }
})
export default class NotificationBar extends Vue {
    isOpen = true;
}
