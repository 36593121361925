import store from '@/store';

/** Prevents click action on an element in Preview mode
 *  Example Usage
 * <button
 *  v-prevent-click-in-preview
 *  @click="doSomething">
 *  Something
 * </button>
 */
export default {
    mounted: (element: HTMLElement) => {
        element.addEventListener('click', event => {
            if (store.state.subscriber.previewMode) {
                event.stopImmediatePropagation(); // prevent click for buttons
                event.preventDefault(); // prevent click for <a>, <input>, <div>
            }
        }, { capture: true });
    }
};

export function isTestProduct(productId: string): boolean {
    const remoteId = productId.replace(/^shopify-/, '');
    return parseInt(remoteId, 10) === 0;
}
