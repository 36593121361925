
import { AttributeView, ProductRecorderView, ProfileQuestion, ProfileQuestionAnswer, ReviewResource } from '@okendo/reviews-common';
import { Options, Vue } from 'vue-class-component';
import { uuid } from 'vue-uuid';

import Attribute from '@/shared-components/Attribute.vue';
import MediaUploader from '@/shared-components/MediaUploader.vue';
import PoweredByOkendo from '@/shared-components/PoweredByOkendo.vue';
import StarRating from '@/shared-components/StarRating.vue';
import ProductImage from '@/shared-components/ProductImage.vue';
import Profile from '@/shared-components/profile/Profile.vue';
import Terms from '@/shared-components/Terms.vue';
import store from '@/store';
import { StoreMethod } from '@/store/storeTypings';
import { getProfileQuestionAnswers, postAnalytics, postReview } from '@/utils/api';
import { escapeTags, formatQueryStrings } from '@/utils/vueUtils';
import { getCountries, getCountryZones } from '@/utils/countriesUtil';
import { formatMediaToTranscode } from '@/utils/mediaUploadUtil';
import { isReviewUpgradeable } from '@/utils/upgradeReviewUtil';
import { updateRewardPrompt } from '@/utils/rewardUtils';
import { getFingerprint, setupDeviceFingerprintScript } from '@/utils/fingerprintUtil';
import { isEmailAddress, scrollToFirstErrorElement, validateRules } from '@/utils/validationUtil';
import { loadChannelSurvey, loadSurveySettings } from '@/utils/surveyUtil';
import { loadReferralSettings } from '@/utils/referralUtil';
import { isLoyaltyModule } from '@/utils/loyaltyUtils';
import { LoyaltyAuthModuleMethod } from '@/store/modules/loyalty-auth';
import { postLogin } from '@/utils/loyaltyApi';
import { LoyaltyModuleMethod } from '@/store/modules/loyalty';
import type { PostReviewModules } from '@/views/post-response/PostReview.vue';

@Options({
    components: {
        Attribute,
        StarRating,
        Profile,
        MediaUploader,
        PoweredByOkendo,
        ProductImage,
        Terms
    },
    store
})
export default class ReviewForm extends Vue {
    hasSubmitted = false;
    isSending = false;
    mediaBlocks: MediaBlock[] = [];
    attributeValues: Record<string, any> = {};
    attributesWithIds: AttributeRecorderView[] = [];

    created(): void {
        const {
            review: { review },
            profile: { reviewerProfile },
            product: { product }
        } = store.state;

        this.rating = this.rating !== '0' ? this.rating : review?.rating.toString() || '0';
        this.title = this.title || review?.title || '';
        this.body = this.body || review?.body || '';

        this.attributesWithIds = product.attributes?.map(attribute => {
            return this.isProfileQuestion(attribute) ? attribute : { ...attribute, attributeId: uuid.v4() };
        }) ?? [];

        // Set initial attribute values
        this.setProfileQuestionAnswers();

        if (reviewerProfile.countryCode) {
            // Profile question location
            const locationProfileQuestion = product.attributes?.find(attribute => attribute.type === 'location');
            if (locationProfileQuestion && locationProfileQuestion.profileQuestionId) {
                this.attributeValues[locationProfileQuestion.profileQuestionId] = { countryCode: reviewerProfile.countryCode };
            }
            // Legacy location question
            if (this.countryAttribute && product.hasCountryAttribute && !!this.countryAttributeId) {
                this.attributeValues[this.countryAttributeId] = reviewerProfile.countryCode;
            }
        }

        setupDeviceFingerprintScript();

        if (this.isSurveyModuleActive) {
            loadSurveySettings();
        }

        if (this.isReferralModuleActive) {
            loadReferralSettings();
        }
    }

    get countryAttributeId(): string | undefined {
        const countryAttribute = this.countryAttribute;
        const locationProfileQuestion = this.attributesWithIds.find(a => a.type === 'location');
        if (countryAttribute && !this.isProfileQuestion(countryAttribute)) {
            return countryAttribute.attributeId;
        }
        if (locationProfileQuestion && this.isProfileQuestion(locationProfileQuestion)) {
            return locationProfileQuestion.profileQuestionId;
        }
    }

    get countryAttribute(): AttributeRecorderView | undefined {
        return this.attributesWithIds.find(a => a.type === 'drop-down' && a.dataSource === 'country');
    }

    get countryCode(): string | undefined {
        return this.countryAttributeId
            ? this.attributeValues[this.countryAttributeId]?.countryCode ?? this.attributeValues[this.countryAttributeId]
            : undefined;
    }

    get zoneCode(): string | undefined {
        const zoneAttribute = this.attributesWithIds.find(a => a.type === 'drop-down' && a.dataSource === 'zone');
        const locationProfileQuestion = this.attributesWithIds.find(a => a.type === 'location');
        if (zoneAttribute && !this.isProfileQuestion(zoneAttribute)) {
            return this.attributeValues[zoneAttribute.attributeId];
        }
        if (locationProfileQuestion && this.isProfileQuestion(locationProfileQuestion)) {
            return this.attributeValues[locationProfileQuestion.profileQuestionId]?.zoneCode;
        }
    }

    get isOptedIn(): boolean {
        return store.state.profile.isOptedIn;
    }

    get product(): ProductRecorderView {
        return store.state.product.product;
    }

    get rating(): string {
        return store.state.reviewForm.rating;
    }

    set rating(rating: string) {
        if (rating !== '0') {
            postAnalytics({
                eventName: 'change-rating',
                label: 'rating',
                value: rating
            });
        }

        store.commit<StoreMethod>({
            type: 'reviewForm/UPDATE_RATING',
            rating
        });
    }

    get body(): string {
        return store.state.reviewForm.body;
    }

    set body(body: string) {
        store.commit<StoreMethod>({
            type: 'reviewForm/UPDATE_BODY',
            body
        });
    }

    get title(): string {
        return store.state.reviewForm.title;
    }

    set title(title: string) {
        store.commit<StoreMethod>({
            type: 'reviewForm/UPDATE_TITLE',
            title
        });
    }

    get sortedAttributes(): AttributeRecorderView[] | undefined {
        const sortCriteria = (attribute: AttributeRecorderView): number => {
            switch (attribute.title) {
                case 'Country':
                    return 1;
                case 'Zone':
                    return 2;
                default:
                    return 0;
            }
        };

        return [...this.attributesWithIds || []].sort((a, b) => sortCriteria(a) - sortCriteria(b));
    }

    get isRatingInvalid(): boolean {
        return this.rating === '0' && this.hasSubmitted;
    }

    get bodyPlaceholderText(): string | undefined {
        const productName = store.state.product.product.name || 'this product';
        return !this.body && this.hasSubmitted
            ? this.$t('Please enter your thoughts about {productName}', { productName })
            : undefined;
    }

    get titlePlaceholderText(): string | undefined {
        return !this.title && this.hasSubmitted
            ? this.$t('Please enter a title')
            : undefined;
    }

    get isMediaCaptureEnabled(): boolean {
        const { settings } = store.state.settings;
        return !settings.disableMediaCapture;
    }

    get isPreviewMode(): boolean {
        return store.state.subscriber.previewMode;
    }

    get isEmailInputVisible(): boolean {
        const { reviewRequestId } = store.state.order;
        return !reviewRequestId || reviewRequestId === 'test';
    }

    get isReferralModuleActive(): boolean | undefined {
        const { settings } = store.state.settings;
        return settings.postReviewModules?.some(module => module.type === 'referral');
    }

    get isSurveyModuleActive(): boolean | undefined {
        const { settings } = store.state.settings;
        return settings.postReviewModules?.some(module => module.type === 'survey');
    }

    isReviewFormValid(): boolean {
        const { reviewerProfile: { name, email } } = store.state.profile;

        const areFieldsValid: Record<string, boolean> = {
            'Please select a <strong>Star Rating</strong>': this.rating !== '0',
            'Please enter a <strong>Review Title</strong>': !!this.title,
            'Please enter a <strong>Review Body</strong>': !!this.body,
            'Please enter your <strong>Name</strong>': !!name,
            'Please enter your <strong>Email Address</strong>': !this.isEmailInputVisible || isEmailAddress(email)
        };

        const invalidAttributes = this.getInvalidAttributes();
        return validateRules(areFieldsValid, invalidAttributes);
    }

    async submitReviewForm(): Promise<void> {
        if (this.isSending || this.isPreviewMode) {
            return;
        }

        if (this.mediaBlocks.some(media => media.progress < 100)) {
            store.dispatch<StoreMethod>({
                type: 'alert/SHOW',
                alertData: {
                    content: this.$t('Please wait for media to finish uploading before submitting'),
                    status: 'fail'
                }
            });
            return;
        }

        this.hasSubmitted = true;
        if (!this.isReviewFormValid()) {
            scrollToFirstErrorElement();
            return;
        }

        this.isSending = true;

        const {
            order: { elementId, remainingProductsToReview, reviewRequestId },
            profile: { reviewerProfile },
            verification: { emailVerificationToken }
        } = store.state;

        const rating = Math.min(parseInt(this.rating, 10), 5);
        const productId = this.product.productId;
        const media = formatMediaToTranscode(this.mediaBlocks);

        const reviewer = this.getReviewer();
        const reviewerEmail = (reviewerProfile.email || '').trim();

        const reviewData: ReviewResource.CreateReview.Review = {
            attributesWithRating: this.getAttributesWithRating(),
            body: this.body,
            elementId,
            fingerprint: getFingerprint(),
            media,
            productAttributes: this.getProductAttributes(),
            rating,
            reviewer,
            reviewerEmail,
            reviewRequestId,
            title: this.title,
            emailVerificationToken: emailVerificationToken
        };

        // TODO: Check the code to see if it was successful (403 is showing as a success)
        try {
            const { review, reward, editToken, loyaltyCustomer } = await postReview(productId, reviewData);
            postAnalytics({
                eventName: 'action-submitted-review',
                label: 'reviewId',
                value: review.reviewId
            });

            if (editToken) {
                store.commit<StoreMethod>({
                    type: 'review/UPDATE_EDIT_TOKEN',
                    editToken
                });
            }

            store.commit<StoreMethod>({
                type: 'profile/UPDATE_IS_OPTED_IN',
                isOptedIn: true
            });

            store.commit<StoreMethod>({
                type: 'review/UPDATE_REVIEW',
                review,
                reviewerName: reviewerProfile.name
            });

            store.commit<StoreMethod>({
                type: 'review/UPDATE_VIDEO_THUMBNAILS',
                videoThumbnails: store.state.review.videoThumbnails.filter(t => media?.some(m => m.streamId === t.streamId))
            });

            if (reward) {
                store.commit<StoreMethod>({
                    type: 'reward/UPDATE_REWARD',
                    achievedReward: reward.achieved
                });

                if (reward.previouslyAchieved) {
                    store.commit<StoreMethod>({
                        type: 'reward/UPDATE_PREVIOUS_REWARD',
                        previouslyAchievedReward: reward.previouslyAchieved
                    });
                }

                await updateRewardPrompt(media);

                if (reviewRequestId && loyaltyCustomer?.loyaltyCustomerId) {
                    const { postReviewModules } = store.state.settings.settings;

                    if (postReviewModules?.some((module: PostReviewModules) => isLoyaltyModule(module.type))) {
                        import(/* webpackChunkName: "loyalty" */ '../../../store/modules/loyalty').then(
                            ({LoyaltyModule}) => store.registerModule('loyalty', LoyaltyModule)
                        );

                        import(/* webpackChunkName: "loyalty" */ '../../../store/modules/loyalty-auth').then(({LoyaltyAuthModule}) => {
                            store.registerModule('loyaltyAuth', LoyaltyAuthModule);

                            this.$nextTick(() => {
                                postLogin(reviewRequestId, loyaltyCustomer?.loyaltyCustomerId).then(({ jwt: token }) => {
                                    store.commit<LoyaltyModuleMethod>({
                                        type: 'loyalty/SET_LOYALTY_CUSTOMER_ID',
                                        loyaltyCustomerId: loyaltyCustomer.loyaltyCustomerId
                                    });

                                    store.commit<LoyaltyAuthModuleMethod>({ type: 'loyaltyAuth/SET_TOKEN', token });
                                })
                                    .catch();
                            });
                        });
                    }
                }
            }

            const updatedProductsToReview = remainingProductsToReview?.filter(product => product.productId !== productId);

            if (this.isSurveyModuleActive) {
                loadChannelSurvey();
            }

            store.commit<StoreMethod>({
                type: 'order/UPDATE_ORDER',
                remainingProductsToReview: updatedProductsToReview
            });

            const profileQuestionAnswers = this.attributesWithIds
                .reduce<{ [profileQuestionId: string]: ProfileQuestionAnswer.Data }>((result, attribute) => {
                    if (this.isProfileQuestion(attribute)) {
                        const profileQuestionId = attribute.profileQuestionId;
                        const type = attribute.type === 'drop-down' ? 'single-value' : attribute.type as ProfileQuestionAnswer.Data['type'];
                        const data = {
                            ...attribute,
                            type,
                            value: this.attributeValues[profileQuestionId]
                        };
                        result[profileQuestionId] = data;
                    }
                    return result;
                }, {});

            store.commit<StoreMethod>({
                type: 'product/UPDATE_PROFILE_QUESTION_ANSWERS',
                profileQuestionAnswers
            });

            this.resetForm();

            const path = this.postReviewPath;
            const query = formatQueryStrings(this.$route.query);
            if (editToken && reviewRequestId && elementId) {
                query['editToken'] = editToken;
            }

            if (loyaltyCustomer?.loyaltyCustomerId) {
                query['loyaltyCustomerId'] = loyaltyCustomer.loyaltyCustomerId;
            }

            this.$router.replace({
                path,
                query
            });
        }
        catch {
            store.dispatch<StoreMethod>({
                type: 'alert/SHOW',
                alertData: {
                    content: this.$t('Review could not be submitted, please try again'),
                    status: 'fail'
                }
            });
        }

        this.isSending = false;
    }

    get postReviewPath(): string {
        const { skipReviewEnhancementStep } = store.state.settings.settings;
        const isUpgradeable = isReviewUpgradeable(this.mediaBlocks);

        return isUpgradeable && !skipReviewEnhancementStep ? 'review-enhancement' : 'post-review';
    }

    getReviewer(): ReviewResource.CreateReviewBase.Reviewer {
        const { profile: { reviewerProfile } } = store.state;

        if (!reviewerProfile.name) {
            throw new Error('reviewerProfile name is blank or empty, this function assumes the profile to have already been validated.');
        }

        const countryName = getCountries().find(country => country.value === this.countryCode)?.displayName;
        const location = this.countryCode ? {
            country: {
                code: this.countryCode,
                name: countryName
            },
            zoneCode: this.zoneCode
        } : undefined;

        return {
            name: reviewerProfile.name,
            attributes: this.getReviewerAttributes(),
            avatarUrl: reviewerProfile.imageUrl,
            socialConnection: reviewerProfile.socialMediaType || undefined,
            location,
            customAvatar: reviewerProfile.customAvatar,
            socialConnectionUserId: reviewerProfile.socialMediaUserId
        };
    }

    getReviewerAttributes(): ReviewResource.CreateReviewBase.Reviewer.Attribute[] | undefined {
        return this.attributesWithIds?.reduce((acc: ReviewResource.CreateReviewBase.Reviewer.Attribute[], attribute: AttributeRecorderView) => {
            const isProfileQuestion = this.isProfileQuestion(attribute);

            const isReviewerAttribute = attribute.target === 'shopper' || isProfileQuestion;
            const isCorrectType = attribute.type !== 'range' && attribute.type !== 'centered-range' && !this.isLegacyLocationAttribute(attribute);
            const hasValue = !!this.attributeValues[isProfileQuestion ? attribute.profileQuestionId : attribute.attributeId];

            if (isReviewerAttribute && isCorrectType && hasValue) {
                acc.push({
                    title: attribute.title,
                    value: this.attributeValues[isProfileQuestion ? attribute.profileQuestionId : attribute.attributeId],
                    type: attribute.type,
                    isPrivate: attribute.isPrivate,
                    ...(isProfileQuestion ? { profileQuestionId: attribute.profileQuestionId } : {})
                });
            }
            return acc;
        }, []);
    }

    async setProfileQuestionAnswers(): Promise<void> {
        const reviewRequestId = store.state.order.reviewRequestId;
        const { attributes, productId } = store.state.product.product;
        const profileQuestionIds = attributes?.filter(attribute => 'profileQuestionId' in attribute && attribute.profileQuestionId)
            .map(profileQuestion => profileQuestion.profileQuestionId!);

        if (!reviewRequestId || !profileQuestionIds?.length || !productId) {
            return;
        }

        let profileQuestionAnswers: Record<ProfileQuestion.Id, ProfileQuestionAnswer.Data> | undefined;
        try {
            const response = await getProfileQuestionAnswers({
                reviewRequestId,
                profileQuestionIds,
                productId
            });

            profileQuestionAnswers = response.profileQuestionAnswers;
        }
        catch {
            // profileQuestionAnswers are an optional enhancement - skip if unable to be loaded
        }

        if (profileQuestionAnswers) {
            Object.keys(profileQuestionAnswers).forEach(profileQuestionId => {
                this.attributeValues[profileQuestionId] = this.attributeValues[profileQuestionId] ?? profileQuestionAnswers?.[profileQuestionId].value;
            });

            store.commit<StoreMethod>({
                type: 'product/UPDATE_PROFILE_QUESTION_ANSWERS',
                profileQuestionAnswers
            });
        }
    }

    isLegacyLocationAttribute(attribute: AttributeRecorderView): boolean {
        return attribute.type === 'drop-down' && (attribute.dataSource === 'country' || attribute.dataSource === 'zone');
    }

    getAttributesWithRating(): ReviewResource.CreateReviewBase.Attribute.Range[] | undefined {
        return this.attributesWithIds?.reduce((acc: ReviewResource.CreateReviewBase.Attribute.Range[], attribute: AttributeRecorderView) => {
            if (!this.isProfileQuestion(attribute) && this.attributeValues[attribute.attributeId]
                && attribute.target === 'general'
                && (attribute.type === 'range' || attribute.type === 'centered-range')) {
                acc.push({
                    title: attribute.title,
                    value: parseFloat(this.attributeValues[attribute.attributeId]),
                    type: attribute.type,
                    isPrivate: attribute.isPrivate,
                    minLabel: attribute.minLabel,
                    midLabel: attribute.type === 'centered-range' ? attribute.midLabel : undefined,
                    maxLabel: attribute.maxLabel
                });
            }
            return acc;
        }, []);
    }

    getProductAttributes(): ReviewResource.CreateReviewBase.Attribute.Select[] | undefined {
        return this.attributesWithIds?.reduce((acc: ReviewResource.CreateReviewBase.Attribute.Select[], attribute: AttributeRecorderView) => {
            if (!this.isProfileQuestion(attribute) && this.attributeValues[attribute.attributeId]
                && attribute.target === 'general'
                && (attribute.type === 'single-value' || attribute.type === 'multi-value' || attribute.type === 'drop-down')) {
                acc.push({
                    title: attribute.title,
                    value: this.attributeValues[attribute.attributeId],
                    type: attribute.type,
                    isPrivate: attribute.isPrivate
                });
            }
            return acc;
        }, []);
    }

    isRequiredAttributeEmpty(attribute: AttributeRecorderView): boolean {
        const attributeValue = this.isProfileQuestion(attribute)
            ? this.attributeValues[attribute.profileQuestionId]
            : this.attributeValues[attribute.attributeId];
        const isOptional = this.isAttributeOptional(attribute) || this.isHiddenZone(attribute);

        if (attribute.type === 'date-components') {
            const isEmpty = !attributeValue;
            if (isEmpty) {
                return !isOptional;
            }

            const isIncomplete = attribute.components.includes('day') && !attributeValue.day
                || attribute.components.includes('month') && !attributeValue.month
                || attribute.components.includes('year') && !attributeValue.year;

            return isIncomplete;
        }

        return !isOptional && !attributeValue;
    }

    isAttributeOptional(attribute: AttributeRecorderView): boolean {
        switch (attribute.type) {
            case 'multi-value':
                return true;
            case 'single-value':
            case 'drop-down':
            case 'location':
            case 'date-components':
                return !!attribute.isOptional;
            default:
                return false;
        }
    }

    getInvalidAttributes(): string[] {
        return (this.attributesWithIds || []).reduce((acc, attribute) => {
            if (this.isRequiredAttributeEmpty(attribute)) {
                const title = this.isZone(attribute) ? this.getZoneTitle() : escapeTags(attribute.title);
                const missingAttributeMessage = this.$t('Please select an option for <strong>{title}</strong>', { title });
                acc.push(missingAttributeMessage);
            }
            return acc;
        }, [] as string[]);
    }

    isZone(attribute: AttributeRecorderView): boolean {
        return attribute.type === 'drop-down' && attribute.dataSource === 'zone';
    }

    isProfileQuestion(attribute: AttributeRecorderView | AttributeView.DataAttribute): attribute is ProfileQuestionWithId {
        return 'profileQuestionId' in attribute;
    }

    getZoneTitle(): string {
        return getCountryZones().find(zone => zone.countryCode === this.countryCode)?.zoneDisplayName ?? 'Zone';
    }

    isHiddenZone(attribute: AttributeRecorderView): boolean {
        if (this.isZone(attribute) && !!this.countryAttributeId) {
            return !(this.countryCode && getCountryZones().some(zone => zone.countryCode === this.countryCode));
        }

        return false;
    }

    resetForm() {
        store.commit<StoreMethod>({
            type: 'reviewForm/UPDATE_TITLE',
            title: ''
        });

        store.commit<StoreMethod>({
            type: 'reviewForm/UPDATE_BODY',
            body: ''
        });
        this.hasSubmitted = false;
        this.mediaBlocks = [];
    }
}

type ProfileQuestionWithId = AttributeView.DataAttribute & { profileQuestionId: string };
type AttributeWithId = AttributeView.DataAttribute & { attributeId: string };
export type AttributeRecorderView = ProfileQuestionWithId | AttributeWithId;
