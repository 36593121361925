import router from '@/router';

export function isQuestionRoute(): boolean {
    const routeName = router.currentRoute.value.name;
    if (!routeName) {
        return false;
    }

    const questionRouteNames = ['Question', 'Post Question'];
    return questionRouteNames.includes(String(routeName));
}
