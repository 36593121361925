import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';

import { postErrorAnalytics } from '@/utils/api';

@Module({ namespaced: true })
export class IconModule extends VuexModule {
    map = new Map<string, string>();

    @Mutation
    GET_ICON({ url }: GetIcon) {
        if (!this.map.get(url)) {
            axios.get(url)
                .then(res => {
                    const contentType = res.headers['content-type'];

                    if (contentType.includes('image')) {
                        this.map.set(url, res.data);
                    }
                    else {
                        throw new Error(`Attempt to load icon url: ${url} returned content-type: ${contentType}`);
                    }
                })
                .catch(error => {
                    postErrorAnalytics(error);
                });
        }
    }
}

export type IconModuleMethod = GetIcon;

interface GetIcon {
    type: 'icon/GET_ICON';
    url: string;
}
