import { ProductLike } from '@okendo/reviews-common';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export class OrderModule extends VuexModule {
    remainingProductsToReview?: ProductLike[];
    reviewedProductIds?: string[];
    reviewRequestId?: string;
    elementId?: string;

    @Mutation
    UPDATE_ORDER(updatedOrder: UpdateOrder) {
        const { remainingProductsToReview, reviewedProductIds, reviewRequestId, elementId } = updatedOrder;
        this.remainingProductsToReview = remainingProductsToReview ?? this.remainingProductsToReview;
        this.reviewedProductIds = reviewedProductIds ?? this.reviewedProductIds;
        this.reviewRequestId = reviewRequestId ?? this.reviewRequestId;
        this.elementId = elementId ?? this.elementId;
    }
}

export type OrderModuleMethod = UpdateOrder;

interface UpdateOrder {
    type: 'order/UPDATE_ORDER';
    remainingProductsToReview?: ProductLike[];
    reviewedProductIds?: string[];
    reviewRequestId?: string;
    elementId?: string;
}
