
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        modelValue: Array,
        options: Array
    },
    emits: {
        'update:modelValue': (v: string[]) => v.every(x => typeof x === 'string')
    }
})
export default class MultiTag extends Vue {
    modelValue?: string[];
    attributeName = `multiTag-${Math.floor(Math.random() * 1000000)}`;

    updateModelValue(value: string): void {
        let newModelValue = this.modelValue || [];
        if (newModelValue.includes(value)) {
            newModelValue = newModelValue.filter(t => t !== value);
        }
        else {
            newModelValue = [...newModelValue, value];
        }
        this.$emit('update:modelValue', newModelValue);
    }

    isDisabled(option: string): boolean {
        return (this.modelValue?.length ?? 0) > 4 && !this.modelValue?.includes(option);
    }
}
