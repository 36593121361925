
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        content: {
            type: String,
            default: 'Success!'
        },
        status: {
            type: String,
            default: 'success',
            validator: (status: string) => ['success', 'fail', 'info', 'warning'].includes(status)
        },
        okeClass: String
    }
})
export default class Bubble extends Vue {
    status!: string;

    get translatedStatus(): string {
        return this.$t(this.status);
    }
}
