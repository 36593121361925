import { createStore, Store } from 'vuex';

import { AlertModule } from './modules/alert';
import { ReviewFormModule } from './modules/review-form';
import { ProfileModule } from './modules/profile';
import { IconModule } from './modules/icon';
import { BannerModule } from './modules/banner';
import { SettingsModule } from './modules/settings';
import { ModuleState } from './storeTypings';
import { OrderModule } from './modules/order';
import { ProductModule } from './modules/product';
import { ReferralModule } from './modules/referral';
import { RewardModule } from './modules/reward';
import { ReviewModule } from './modules/review';
import { SubscriberModule } from './modules/subscriber';
import { SiteReviewModule } from './modules/site-review';
import { VerificationModule } from './modules/verification';
import { ShopMoreProductsModule } from './modules/shop-more-products';
import { SurveyModule } from './modules/survey';

const modules = {
    alert: AlertModule,
    product: ProductModule,
    banner: BannerModule,
    icon: IconModule,
    order: OrderModule,
    profile: ProfileModule,
    referral: ReferralModule,
    review: ReviewModule,
    reviewForm: ReviewFormModule,
    reward: RewardModule,
    settings: SettingsModule,
    shopMoreProducts: ShopMoreProductsModule,
    subscriber: SubscriberModule,
    survey: SurveyModule,
    siteReview: SiteReviewModule,
    verification: VerificationModule
};

export default createStore<ModuleInstance>({ modules }) as Store<ModuleState>;

export type ModuleInstance = {
    [key in keyof typeof modules]: InstanceType<(typeof modules)[key]>;
};
