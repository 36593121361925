import rg4js from 'raygun4js';

export function configureRaygun() {
    rg4js('apiKey', process.env.VUE_APP_RAYGUN_API_KEY!);
    rg4js('withTags', ['recorder', process.env.NODE_ENV!]);
    rg4js('setVersion', require('../package.json').version);
    rg4js('enableCrashReporting', true);
    rg4js('options', {
        debugMode: process.env.NODE_ENV !== 'production',
        ignore3rdPartyErrors: true
    });
}

export function sendToRaygun(err: Error, { vm, info }: Record<string, unknown>) {
    rg4js('send', {
        error: err.message,
        customData: { vm, info }
    });
}
