import { getLocaleTranslationFilename, getLocaleWithFallback } from '@okendo/translations-common';
import { Subscriber } from '@okendo/reviews-common';
import { nextTick } from 'vue';

import i18n from '@/i18n';
import store from '@/store';
import { StoreMethod } from '@/store/storeTypings';

export async function setLanguage(locale: Subscriber.LocaleAndVariant): Promise<void> {
    const validLocale = getLocaleWithFallback(locale);
    const localeFilename = getLocaleTranslationFilename(validLocale.code, validLocale.variant) ?? 'en';
    document.querySelector('html')?.setAttribute('lang', validLocale.code);

    await loadLocaleMessages(localeFilename);
    i18n.global.locale.value = localeFilename;

    store.commit<StoreMethod>({
        type: 'subscriber/UPDATE_LOCALE',
        locale: validLocale
    });
}

async function loadLocaleMessages(localeFilename: string): Promise<void> {
    if (!i18n.global.availableLocales.includes(localeFilename)) {
        const { default: messages } = await import(
        /* webpackChunkName: "locale-[request]" */ `../locales/${localeFilename}.json`
        );

        i18n.global.setLocaleMessage(localeFilename, messages);
    }

    return nextTick();
}
