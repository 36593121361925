
import { Country, getCountries, getCountryZones, Zone } from '@/utils/countriesUtil';
import { AttributeView, ProfileQuestionAnswer } from '@okendo/reviews-common';
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Options({
    props: {
        modelValue: Object,
        components: String,
        isOptional: Boolean
    },
    emits: {
        'update:modelValue': (v: ProfileQuestionAnswer.Data.Location['value']) => typeof v === 'object' && v.countryCode || v === undefined
    }
})
export default class LocationSelect extends Vue {
    attributeName = `location-${Math.floor(Math.random() * 1000000)}`;
    components!: AttributeView.LocationAttribute['components'];
    zoneOptions: Zone[] = [];
    modelValue!: ProfileQuestionAnswer.Data.Location['value'];
    modelCountry: string | null = null;
    modelZone: string | null = null;
    isOptional?: boolean;

    @Watch('modelValue')
    modelValueChange(newModelValue: ProfileQuestionAnswer.Data.Location['value']): void {
        if (newModelValue && !this.modelCountry) {
            this.setModelValues(newModelValue);
        }
    }

    created(): void {
        if (this.modelValue) {
            this.setModelValues(this.modelValue);
        }
    }

    get countryOptions(): Country[] {
        return getCountries();
    }

    get allowZoneSelect(): boolean {
        return this.components === 'country-and-zone';
    }

    setModelValues(modelValue: ProfileQuestionAnswer.Data.Location['value']): void {
        this.modelCountry = modelValue.countryCode;
        this.onCountryChange();
    }

    onCountryChange(): void {
        this.setZoneOptions();
        this.onModelUpdate();
    }

    setZoneOptions(): void {
        this.zoneOptions = getCountryZones().find(zone => zone.countryCode === this.modelCountry)?.zones ?? [];
        this.modelZone = this.zoneOptions[0]?.value;
    }

    onModelUpdate(): void {
        const modelValue: ProfileQuestionAnswer.Data.Location['value'] | undefined = this.modelCountry ? {
            countryCode: this.modelCountry,
            zoneCode: this.modelZone ?? undefined
        } : undefined;

        this.$emit('update:modelValue', modelValue);
    }
}
