import { ReferralSettingsViewPublic } from '@okendo/reviews-common';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export class ReferralModule extends VuexModule {
    referralSettings?: ReferralSettingsViewPublic;

    @Mutation
    UPDATE_REFERRAL_SETTINGS({ referralSettings }: UpdateReferralSettings) {
        this.referralSettings = referralSettings;
    }
}

export type ReferralModuleMethod = UpdateReferralSettings;

interface UpdateReferralSettings {
    type: 'referral/UPDATE_REFERRAL_SETTINGS';
    referralSettings?: ReferralSettingsViewPublic;
}
