
import { Options, Vue } from 'vue-class-component';

import store from '@/store';

@Options({
    props: { hasPreviouslyOptedIn: Boolean },
    store
})
export default class Terms extends Vue {
    hasPreviouslyOptedIn = false;
    termsUrl = 'https://www.okendo.io/end-user-terms/';
    privacyUrl = 'https://www.okendo.io/privacy-policy/';

    get isOptedIn(): boolean {
        return store.state.profile.isOptedIn;
    }

    get keypath(): string {
        return this.hasPreviouslyOptedIn ?
            'Subject to our {termsAndConditions} and {privacyPolicy}' :
            'By continuing you agree to our {termsAndConditions} and {privacyPolicy}';
    }
}
