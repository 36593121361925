import { LocationQueryValue } from 'vue-router';

import { getProduct, getReviewById } from './api';
import { formatQueryStrings } from './vueUtils';
import store from '@/store';
import { StoreMethod } from '@/store/storeTypings';
import i18n from '@/i18n';
import { checkForLocationAttributes } from './countriesUtil';
import { isQuestionRoute } from './routerUtils';

export async function verifyProductId(productId: LocationQueryValue | LocationQueryValue[]): Promise<void> {
    const { product } = store.state.product;
    const { reviewedProductIds } = store.state.order;

    const currentProductId = product.productId;
    const newProductId = formatQueryStrings({ productId }).productId;

    // When navigating, we use the product ID in the query params to see if
    // we need to update the store with the details of the new product
    if (newProductId && newProductId !== currentProductId) {
        const { product: newProduct } = await getProduct(newProductId);
        store.commit<StoreMethod>({
            type: 'product/UPDATE_PRODUCT',
            product: newProduct
        });

        // In case countries weren't loaded for the previous product
        await checkForLocationAttributes(product);

        if (reviewedProductIds?.includes(newProduct.productId)) {
            store.commit<StoreMethod>({
                type: 'product/UPDATE_PREVIOUSLY_REVIEWED',
                isPreviouslyReviewed: true
            });
        }

        const { reviewed } = store.state.review;
        const { reviewRequestId } = store.state.order;

        if (reviewed && reviewRequestId) {
            const reviewId = productId && reviewed.find(reviewIdProductIdMap => reviewIdProductIdMap.productId === productId)?.reviewId;

            if (reviewId) {
                await getReviewById(reviewRequestId, reviewId).then(res => {
                    const { review } = res;

                    store.commit<StoreMethod>({
                        type: 'review/UPDATE_REVIEW',
                        review,
                        reviewerName: review.reviewer.name,
                        reviewed
                    });
                });
            }
            else {
                store.commit<StoreMethod>({
                    type: 'review/CLEAR_REVIEW'
                });
            }
        }
    }

    updatePageTitle();
}

export function updatePageTitle(): void {
    const {
        product: { product },
        subscriber: { storeName }
    } = store.state;

    const translationKey = isQuestionRoute() ? 'Question about {name}' : 'Review {name}';
    const translatedTitle = i18n.global.t(translationKey, {
        name: `${product.name}${storeName ? ` - ${storeName}` : ''}`
    });

    document.title = translatedTitle;
}
