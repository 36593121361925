import { ProductRecorderView, ProfileQuestion, ProfileQuestionAnswer } from '@okendo/reviews-common';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export class ProductModule extends VuexModule {
    product!: ProductRecorderView;
    isPreviouslyReviewed = false;
    profileQuestionAnswers!: Record<ProfileQuestion.Id, ProfileQuestionAnswer.Data>;

    @Mutation
    UPDATE_PRODUCT({ product }: UpdateProduct) {
        this.product = product;
    }

    @Mutation
    UPDATE_PREVIOUSLY_REVIEWED({ isPreviouslyReviewed }: UpdatePreviouslyReviewed) {
        this.isPreviouslyReviewed = isPreviouslyReviewed;
    }

    @Mutation
    UPDATE_PROFILE_QUESTION_ANSWERS({ profileQuestionAnswers }: UpdateProfileQuestionAnswers) {
        this.profileQuestionAnswers = profileQuestionAnswers;
    }
}

export type ProductModuleMethod = UpdateProduct | UpdatePreviouslyReviewed | UpdateProfileQuestionAnswers;

type ModulePrefix = 'product';

interface UpdateProduct {
    type: `${ModulePrefix}/UPDATE_PRODUCT`;
    product: ProductRecorderView;
}

interface UpdatePreviouslyReviewed {
    type: `${ModulePrefix}/UPDATE_PREVIOUSLY_REVIEWED`;
    isPreviouslyReviewed: boolean;
}

interface UpdateProfileQuestionAnswers {
    type: `${ModulePrefix}/UPDATE_PROFILE_QUESTION_ANSWERS`;
    profileQuestionAnswers: { [profileQuestionId: string]: ProfileQuestionAnswer.Data };
}
