{
    "Please enter a rating": "Please enter a rating",
    "Review Title": "Review Title",
    "Please enter a title": "Please enter a title",
    "Review Description": "Review Description",
    "Please enter your thoughts about {productName}": "Please enter your thoughts about {productName}",
    "What did you think about {productName}?": "What did you think about {productName}?",
    "Please enter your question about {productName}": "Please enter your question about {productName}",
    "Submit": "Submit",
    "Agree & Submit": "Agree & Submit",
    "Your review has been submitted successfully!": "Your review has been submitted successfully!",
    "There was a problem submitting your review": "There was a problem submitting your review",
    "Please select a <strong>Star Rating</strong>": "Please select a <strong>Star Rating</strong>",
    "Please enter a <strong>Review Title</strong>": "Please enter a <strong>Review Title</strong>",
    "Please enter a <strong>Review Body</strong>": "Please enter a <strong>Review Body</strong>",
    "Please enter your <strong>Name</strong>": "Please enter your <strong>Name</strong>",
    "Please enter your <strong>Email Address</strong>": "Please enter your <strong>Email Address</strong>",
    "{messagePrefix}\nPlease correct the <strong>{errorsCount} marked errors</strong> and try again": "{messagePrefix}\nPlease correct the <strong>{errorsCount} marked errors</strong> and try again",
    "Please select an option for <strong>{title}</strong>": "Please select an option for <strong>{title}</strong>",
    "Rate {rating} out of 5": "Rate {rating} out of 5",
    "Add Photos or Videos": "Add Photos or Videos",
    "Click here to upload": "Click here to upload",
    "Click here or drag to upload": "Click here or drag to upload",
    "Remove Media": "Remove Media",
    "Your Profile": "Your Profile",
    "Add Profile Picture": "Add Profile Picture",
    "Edit Profile Picture": "Edit Profile Picture",
    "Not you?": "Not you?",
    "OR": "OR",
    "Please enter your name": "Please enter your name",
    "Name": "Name",
    "Please enter your email address": "Please enter your email address",
    "Email Address": "Email Address",
    "Remove Avatar": "Remove Avatar",
    "Upload a Different Photo": "Upload a Different Photo",
    "Upload a Photo": "Upload a Photo",
    "(Optional)": "(Optional)",
    "Remove Photo": "Remove Photo",
    "Continue with {socialMedia}": "Continue with {socialMedia}",
    "Select an option": "Select an option",
    "Choose one": "Choose one",
    "Choose up to 5 options": "Choose up to 5 options",
    "Choose all that apply": "Choose all that apply",
    "Dismiss": "Dismiss",
    "Yes": "Yes",
    "No": "No",
    "Okendo Website": "Okendo Website",
    "Content could not be loaded": "Content could not be loaded",
    "Sorry, this review link has expired": "Sorry, this review link has expired",
    "We are no longer accepting reviews from this link": "We are no longer accepting reviews from this link",
    "We could not find the details for the product you want to review.\n Please contact the store for more information.": "We could not find the details for the product you want to review.\n Please contact the store for more information.",
    "Something went wrong": "Something went wrong :(",
    "Retry": "Retry",
    "No content could be found": "No content could be found",
    "Write Review": "Write Review",
    "Add Photo or Video": "Add Photo or Video",
    "Bonus Questions": "Bonus Questions",
    "Review More Products": "Review More Products",
    "You've already left a review for this product": "You've already left a review for this product",
    "Return to {storeName}": "Return to {storeName}",
    "Store": "Store",
    "Start a new review for {productName}": "Start a new review for {productName}",
    "Upgrade Review": "Upgrade Review",
    "Update Review": "Update Review",
    "Skip": "Skip",
    "Review Submitted!": "Review Submitted!",
    "Review Updated!": "Review Updated!",
    "Please wait for media to finish uploading before submitting": "Please wait for media to finish uploading before submitting",
    "Failed to upload media": "Failed to upload media",
    "Review could not be submitted, please try again": "Review could not be submitted, please try again",
    "Thanks for sharing your feedback with us!": "Thanks for sharing your feedback with us!",
    "Have been added to your account": "Have been added to your account",
    "Has been added to your account": "Has been added to your account",
    "You've Earned {points}!": "You've Earned {points}!",
    "You may have qualified for a reward! Check the rewards program for more details": "You may have qualified for a reward! Check the rewards program for more details",
    "You've Earned a Coupon!": "You've Earned a Coupon!",
    "Please find your unique coupon code below. It has also been emailed to you": "Please find your unique coupon code below. It has also been emailed to you",
    "Please select an option": "Please select an option",
    "Add to Your Review": "Add to Your Review",
    "Photos and Videos": "Photos and Videos",
    "Profile picture must be an image": "Profile picture must be an image",
    "Upload must be an image or video": "Upload must be an image or video",
    "You have added more images and videos than are allowed, some have not been included": "You have added more images and videos than are allowed, some have not been included",
    "You have reached the maximum number of images and videos which can be added": "You have reached the maximum number of images and videos which can be added",
    "Close Dialog": "Close Dialog",
    "success": "success",
    "fail": "fail",
    "info": "info",
    "warning": "warning",
    "Error Image": "Error Image",
    "404 Not Found": "404 Not Found",
    "Sorry, this store doesn't allow anonymous reviews": "Sorry, this store doesn't allow anonymous reviews",
    "Please check you have the correct url address or contact the store for more details": "Please check you have the correct url address or contact the store for more details",
    "Thank you for your previous uploads, add more below": "Thank you for your previous uploads, add more below",
    "Your answer won't be shared publicly": "Your answer won't be shared publicly",
    "Ask a question about {productName}": "Ask a question about {productName}",
    "Question": "Question",
    "Please enter a <strong>Question</strong>": "Please enter a <strong>Question</strong>",
    "Question about {name}": "Question about {name}",
    "Your question has been submitted.": "Your question has been submitted.",
    "We'll send you an email once your question has been answered.": "We'll send you an email once your question has been answered.",
    "By continuing you agree to our {termsAndConditions} and {privacyPolicy}": "By continuing you agree to our {termsAndConditions} and {privacyPolicy}",
    "Subject to our {termsAndConditions} and {privacyPolicy}": "Subject to our {termsAndConditions} and {privacyPolicy}",
    "Terms and Conditions": "Terms and Conditions",
    "Privacy Policy": "Privacy Policy",
    "bannerBody": "Submit your review { bannerAdditionalRequirement } { bannerActionSuffix } and earn { bannerActionCoupon } { bannerBodyStrongText }!",
    "bannerBody-update": "Update your review { bannerAdditionalRequirement } { bannerActionSuffix } and earn { bannerActionCoupon } { bannerBodyStrongText }!",
    "and add a {requirement}": "and add a {requirement}",
    "with a {requirement}": "with a {requirement}",
    "a coupon for": "a coupon for",
    "upgradeByAddingMore": "{upgradeYourReward} by adding more:",
    "Upgrade your coupon": "Upgrade your coupon",
    "Earn additional points": "Earn additional points",
    "Collapse upgrade rewards": "Collapse upgrade rewards",
    "Expand upgrade rewards": "Expand upgrade rewards",
    "Up to {rewardLimitText} points can be earned for this review": "Up to {rewardLimitText} points can be earned for this review",
    "Up to {rewardLimitText} more points can be earned for this review": "Up to {rewardLimitText} more points can be earned for this review",
    "upgradeReward": "{upgradeRewardValue} if you add a {upgradeRewardRequirement}",
    "photo": "photo",
    "video": "video",
    "profile picture": "profile picture",
    "loyalty point": "loyalty point",
    "loyalty points": "loyalty points",
    "How would you rate the product on the following aspects:": "How would you rate the product on the following aspects:",
    "Your next purchase at {storeName}": "Your next purchase at {storeName}",
    "Your next purchase from the {collectionName} at {storeName}": "Your next purchase from the {collectionName} at {storeName}",
    "Refer a friend": "Refer a friend",
    "Recommend {storeName} to a friend and receive a {couponValue} coupon if they make a purchase": "Recommend {storeName} to a friend and receive a {couponValue} coupon if they make a purchase",
    "Shop More Top-Reviewed Products": "Shop More Top-Reviewed Products",
    "{reviewsCount} Review": "{reviewsCount} Review|{reviewsCount} Reviews",
    "Shop Now": "Shop Now",
    "Review {name}": "Review {name}",
    "Shipping": "Shipping",
    "Customer Service": "Customer Service",
    "How likely would you be to recommend {storeName} to a friend?": "How likely would you be to recommend {storeName} to a friend?",
    "Very Unlikely": "Very Unlikely",
    "Very Likely": "Very Likely",
    "Request sent to {email}": "Request sent to {email}",
    "Review submitted, thanks for your feedback!": "Review submitted, thanks for your feedback!",
    "Thanks for taking the time to give us your feedback": "Thanks for taking the time to give us your feedback",
    "Please Verify Your Email Address": "Please Verify Your Email Address",
    "You will receive an email with a verification link soon": "You will receive an email with a verification link soon",
    "Thank you!": "Thank you!",
    "Your review has been successfully verified": "Your review has been successfully verified",
    "Continue to Store": "Continue to Store",
    "Email address is required to submit your review. Please try again, or continue as guest": "Email address is required to submit your review. Please try again, or continue as guest",
    "Store Website": "Store Website",
    "How would you rate your overall experience with us?": "How would you rate your overall experience with us?",
    "Please describe your overall experience with us": "Please describe your overall experience with us",
    "Please describe your <strong>Overall Experience</strong>": "Please describe your <strong>Overall Experience</strong>",
    "Please rate our <strong>Shipping</strong>": "Please rate our <strong>Shipping</strong>",
    "Please rate our <strong>Customer Service</strong>": "Please rate our <strong>Customer Service</strong>",
    "Please select how likely you would be to <strong>Recommend us to a Friend</strong>": "Please select how likely you would be to <strong>Recommend us to a Friend</strong>",
    "Site Review Title": "Site Review Title",
    "Site Review Description": "Site Review Description",
    "Failed to save site review, please try again.": "Failed to save site review, please try again.",
    "{currencySymbol}{value} off": "{currencySymbol}{value} off",
    "{value}% off": "{value}% off",
    "Coupon Upgraded!": "Coupon Upgraded!",
    "Click to copy": "Click to copy",
    "Copied": "Copied",
    "Day": "Day",
    "Month": "Month",
    "Year": "Year",
    "January": "January",
    "February": "February",
    "March": "March",
    "April": "April",
    "May": "May",
    "June": "June",
    "July": "July",
    "August": "August",
    "September": "September",
    "October": "October",
    "November": "November",
    "December": "December",
    "Country": "Country",
    "Zone": "Zone",
    "Join {programName} now to redeem rewards and unlock exclusive perks!": "Join {programName} now to redeem rewards and unlock exclusive perks!",
    "You have {points} waiting": "You have {points} waiting",
    "Verification Email Sent": "Verification Email Sent",
    "We've sent an email to the email address you provided, please check your emails and follow the sign up link": "We've sent an email to the email address you provided, please check your emails and follow the sign up link",
    "Join Program": "Join Program",
    "Expires {expiryDate}": "Expires {expiryDate}",
    "Coupon could not be copied. Please copy manually.": "Coupon could not be copied. Please copy manually.",
    "Coupon copied to clipboard": "Coupon copied to clipboard",
    "Use Your Rewards": "Use Your Rewards",
    "Your Balance": "Your Balance",
    "Your perks are waiting": "Your perks are waiting",
    "Login failed, please try again.": "Login failed, please try again.",
    "Move Up Tiers": "Move Up Tiers",
    "Shop now": "Shop now",
    "Tier Perks": "Tier Perks",
    "Spent {currencySymbol}{totalSpend}": "Spent {currencySymbol}{totalSpend}",
    "Spent {currencySymbol}{totalSpend} since {dateSpendFrom}": "Spent {currencySymbol}{totalSpend} since {dateSpendFrom}",
    "Spend {currencySymbol}{value} more by {date} to reach the {tierName} tier.": "Spend {currencySymbol}{value} more by {date} to reach the {tierName} tier.",
    "Spend {currencySymbol}{value} more to reach the {tierName} tier.": "Spend {currencySymbol}{value} more to reach the {tierName} tier.",
    "Spend {currencySymbol}{value} more by {date} to remain in {currentTierName} tier, otherwise you'll be moved down to {previousTierName}.": "Spend {currencySymbol}{value} more by {date} to remain in {currentTierName} tier, otherwise you'll be moved down to {previousTierName}.",
    "Spend {currencySymbol}{value} more to remain in {currentTierName} tier, otherwise you'll be moved down to {previousTierName}.": "Spend {currencySymbol}{value} more to remain in {currentTierName} tier, otherwise you'll be moved down to {previousTierName}.",
    "Congratulations, you've reached the highest tier": "Congratulations, you've reached the highest tier",
    "Error, this reward may have already been redeemed": "Error, this reward may have already been redeemed",
    "Redeem Rewards": "Redeem Rewards",
    "Receive {currencySymbol}{value} for every {points}": "Receive {currencySymbol}{value} for every {points}",
    "to spend": "to spend",
    "Error occurred redeeming reward, please try again": "Error occurred redeeming reward, please try again",
    "Go": "Go",
    "Set Date": "Set Date",
    "Edit Date": "Edit Date",
    "Earn More Points": "Earn More Points",
    "Redeem": "Redeem",
    "Reward redeemed!": "Reward redeemed!",
    "Unable to Load Loyalty Rules": "Unable to Load Loyalty Rules",
    "Any coupon codes you redeem will appear here": "Any coupon codes you redeem will appear here",
    "Save": "Save",
    "Birthday saved": "Birthday saved",
    "Failed to update birthday, please try again": "Failed to update birthday, please try again",
    "You can only receive {rewardCurrencyPlural} for your birthday once every 12 months. If you've already been awarded {rewardCurrencyPlural} this year, you won't receive more {rewardCurrencyPlural} for your birthday until the following year.": "You can only receive {rewardCurrencyPlural} for your birthday once every 12 months. If you've already been awarded {rewardCurrencyPlural} this year, you won't receive more {rewardCurrencyPlural} for your birthday until the following year."
}
