import { MediaToTranscode, Subscriber } from '@okendo/reviews-common';

import { postMedia } from './api';
import router from '@/router/index';

export function mediaCountLimit(): number {
    const route = router.currentRoute.value;
    return route.name === 'Review Enhancement' ? 5 : 20;
}

export async function sendMediaUploadRequest(subscriberId: Subscriber.Id, data: MediaBlock, file: File, callback: ProgressHandler): Promise<boolean> {
    const key = data.type === 'image' ? `images/${subscriberId}/${data.streamId}` : data.streamId;
    const formData = new FormData();

    formData.append('key', key);
    formData.append('acl', 'public-read');
    formData.append('content-type', file.type);
    formData.append('file', file);

    try {
        await postMedia(formData, callback);
        return true;
    }
    catch {
        return false;
    }
}

export function formatDuration(duration: number): string {
    const time = Math.floor(duration);
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds >= 10 ? seconds : `0${seconds}`}`;
}

export function getSecondsFromDuration(duration: string): number {
    const minutes = parseInt(duration.split(':')[0], 10);
    const seconds = parseInt(duration.split(':')[1], 10);
    return minutes * 60 + seconds;
}

export function formatMediaToTranscode(mediaBlocks: MediaBlock[]): MediaToTranscode[] | undefined {
    if (!mediaBlocks.length) {
        return;
    }

    return mediaBlocks?.map(mediaBlock => ({
        type: mediaBlock.type,
        streamId: mediaBlock.streamId,
        mimetype: mediaBlock.type,
        source: 'uploaded'
    }));
}
