
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import store from '@/store';
import { StoreMethod } from '@/store/storeTypings';

@Options({
    props: { 'icon': String },
    store
})
export default class Icon extends Vue {
    icon!: string;

    readonly iconMap = new Map([
        ['success', require('@/assets/icons/success.svg')],
        ['fail', require('@/assets/icons/fail.svg')],
        ['warning', require('@/assets/icons/warning.svg')],
        ['info', require('@/assets/icons/info.svg')],
        ['photo-video', require('@/assets/icons/photo-video.svg')],
        ['image-upload', require('@/assets/icons/image-upload.svg')],
        ['gift', require('@/assets/icons/gift.svg')],
        ['powered-by', require('@/assets/icons/powered-by.svg')],
        ['star-outline', require('@/assets/icons/star-outline.svg')],
        ['close', require('@/assets/icons/close.svg')],
        ['plus', require('@/assets/icons/plus.svg')],
        ['minus', require('@/assets/icons/minus.svg')],
        ['checkbox', require('@/assets/icons/checkbox.svg')],
        ['profile', require('@/assets/icons/profile.svg')],
        ['edit', require('@/assets/icons/edit.svg')],
        ['verified-tick', require('@/assets/icons/verified-tick.svg')],
        ['arrow-right', require('@/assets/icons/arrow-right.svg')],
        ['lock', require('@/assets/icons/lock.svg')]
    ]);

    mounted(): void {
        this.updateIcon(this.icon);
    }

    @Watch('icon')
    onIconChange(icon: string): void {
        this.updateIcon(icon);
    }

    get iconContent(): string | undefined {
        const url = this.iconMap.get(this.icon);
        return store.state.icon.map.get(url);
    }

    updateIcon(icon: string): void {
        const url = this.iconMap.get(icon);
        store.commit<StoreMethod>({
            type: 'icon/GET_ICON',
            url
        });
    }
}
