import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-756c3cc7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-locationSelect" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["value"]
const _hoisted_6 = ["for"]
const _hoisted_7 = ["id"]
const _hoisted_8 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: `${_ctx.attributeName}-country`,
      class: "u-assistive-hidden"
    }, _toDisplayString(_ctx.$t('Country')), 9, _hoisted_2),
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelCountry) = $event)),
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onCountryChange())),
      id: `${_ctx.attributeName}-country`,
      class: _normalizeClass([{ 'isEmpty': !_ctx.modelCountry }, "c-select c-locationSelect-select"])
    }, [
      _createElementVNode("option", {
        value: null,
        disabled: !_ctx.isOptional
      }, _toDisplayString(_ctx.$t('Country')), 9, _hoisted_4),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countryOptions, (country) => {
        return (_openBlock(), _createElementBlock("option", {
          key: country.value,
          value: country.value
        }, _toDisplayString(country.displayName), 9, _hoisted_5))
      }), 128))
    ], 42, _hoisted_3), [
      [_vModelSelect, _ctx.modelCountry]
    ]),
    (_ctx.allowZoneSelect && _ctx.zoneOptions?.length)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("label", {
            for: `${_ctx.attributeName}-zone`,
            class: "u-assistive-hidden"
          }, _toDisplayString(_ctx.$t('Zone')), 9, _hoisted_6),
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelZone) = $event)),
            onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onModelUpdate())),
            id: `${_ctx.attributeName}-zone`,
            class: _normalizeClass([{ 'isEmpty': !_ctx.modelZone }, "c-select c-locationSelect-select"])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.zoneOptions, (zone) => {
              return (_openBlock(), _createElementBlock("option", {
                key: zone.value,
                value: zone.value
              }, _toDisplayString(zone.displayName), 9, _hoisted_8))
            }), 128))
          ], 42, _hoisted_7), [
            [_vModelSelect, _ctx.modelZone]
          ])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}