import { LoyaltyCustomerViewPublic } from '@okendo/reviews-common';

import store from '@/store';
import type { PostReviewModules } from '@/views/post-response/PostReview.vue';

export function isAuthenticated(): boolean {
    return !!store.state.loyaltyAuth?.token;
}

export const exampleLoyaltyMember: LoyaltyCustomerViewPublic = {
    email: 'test-user@example.com',
    fullName: 'Test User',
    points: {
        balance: 1000,
        dateUpdated: '9999-12-12T09:00:00.000Z',
        pending: 0,
        spent: 0
    },
    rewardsClaimed: 0,
    status: 'enrolled'
};

export function isLoyaltyModule(moduleType: PostReviewModules['type']): boolean {
    return moduleType.startsWith('loyalty-');
}
