
import { Options, Vue } from 'vue-class-component';
import { uuid } from 'vue-uuid';
import { Media } from '@okendo/reviews-common';

import Icon from '@/shared-components/Icon.vue';
import store from '@/store';
import { ReviewerProfile } from '@/store/modules/profile';
import { StoreMethod } from '@/store/storeTypings';
import { sendMediaUploadRequest } from '@/utils/mediaUploadUtil';
import { isMobile } from '@/utils/deviceUtils';
import { postAnalytics } from '@/utils/api';

const TRANSCODED_MEDIA_DYNAMIC_BASE_URL = process.env.VUE_APP_TRANSCODED_MEDIA_DYNAMIC_BASE_URL;

@Options({
    props: {
        isInModal: Boolean
    },
    components: {
        Icon
    },
    emits: {
        'uploadComplete': (v: boolean) => typeof v === 'boolean'
    },
    store
})
export default class AvatarUploader extends Vue {
    isUploaderHighlighted = false;
    isUploadingAvatar = false;

    get profile() {
        return store.state.profile.reviewerProfile;
    }

    get isPreviewMode(): boolean {
        return store.state.subscriber.previewMode;
    }

    get clickToUploadText(): string {
        return isMobile()
            ? this.$t('Click here to upload')
            : this.$t('Click here or drag to upload');
    }

    mediaDrag(): void {
        if (!this.isUploaderHighlighted) {
            this.isUploaderHighlighted = true;
        }
    }

    mediaDragLeave(): void {
        if (this.isUploaderHighlighted) {
            this.isUploaderHighlighted = false;
        }
    }

    mediaDrop(dragEvent: DragEvent): void {
        this.isUploaderHighlighted = false;

        if (!this.isUploadingAvatar && !this.isPreviewMode) {
            this.onMediaFileChange(dragEvent.dataTransfer?.files);
        }
    }

    onMediaFileChange(files: FileList | undefined) {
        const file = files?.[0];

        if (!file) {
            return;
        }

        const type = file.type.includes('image') ? 'image' :
            file.type.includes('video') ? 'video' : undefined;

        if (type !== 'image') {
            store.dispatch<StoreMethod>({
                type: 'alert/SHOW',
                alertData: {
                    content: this.$t('Profile picture must be an image'),
                    status: 'fail'
                }
            });
            return;
        }

        this.isUploadingAvatar = true;

        const { subscriberId } = store.state.subscriber;
        const streamId = uuid.v4();
        const dynamicKey = `${subscriberId}/${streamId}`;
        const handler = this.waitForUpload(dynamicKey);
        const media: MediaBlock = {
            streamId,
            name: file.name,
            progress: 0,
            type
        };

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (reader.result) {
                const customAvatarSource = reader.result;
                const customAvatar: Media.CustomAvatar = {
                    streamId,
                    dynamicKey
                };
                media.type = 'image';
                media.src = customAvatarSource;

                postAnalytics({ eventName: 'change-avatar' });

                this.updateReviewerProfile({
                    customAvatar,
                    customAvatarSource
                });

                sendMediaUploadRequest(subscriberId, media, file, handler);
            }
        };
    }

    waitForUpload(dynamicKey: string): ProgressHandler {
        return (progress: number) => {
            if (progress > 99) {
                const imageUrl = `${TRANSCODED_MEDIA_DYNAMIC_BASE_URL}/images/${dynamicKey}.jpg?d=160x160&crop=center`;
                this.updateReviewerProfile({ imageUrl });
                this.$emit('uploadComplete', true);
                this.isUploadingAvatar = false;
            }
        };
    }

    removeAvatar(): void {
        postAnalytics({ eventName: 'click-remove-avatar' });

        store.commit<StoreMethod>({
            type: 'profile/CLEAR_CUSTOM_AVATAR'
        });
        this.$emit('uploadComplete', true);
    }

    updateReviewerProfile(reviewerProfile: ReviewerProfile): void {
        store.commit<StoreMethod>({
            type: 'profile/UPDATE_REVIEWER_PROFILE',
            reviewerProfile
        });
    }
}
