import { RewardCentre } from '@okendo/reviews-common';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export class BannerModule extends VuexModule {
    reward: RewardCentre.RewardPrompt.Response = {};
    isRewardsBannerExpanded = false;

    @Mutation
    UPDATE_ACHIEVABLE_REWARD({ reward }: UpdateAchievableReward) {
        this.reward = reward;
    }

    @Mutation
    UPDATE_IS_BANNER_EXPANDED({ isRewardsBannerExpanded }: UpdateRewardsBannerExpanded) {
        this.isRewardsBannerExpanded = isRewardsBannerExpanded;
    }
}

export type BannerModuleMethod = UpdateAchievableReward | UpdateRewardsBannerExpanded;

interface UpdateAchievableReward {
    type: 'banner/UPDATE_ACHIEVABLE_REWARD';
    reward: RewardCentre.RewardPrompt.Response;
}

interface UpdateRewardsBannerExpanded {
    type: 'banner/UPDATE_IS_BANNER_EXPANDED';
    isRewardsBannerExpanded: boolean;
}
