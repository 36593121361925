
import { AttributeView, ProfileQuestionAnswer } from '@okendo/reviews-common';
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Options({
    props: {
        modelValue: Object,
        components: String,
        isOptional: Boolean
    },
    emits: {
        'update:modelValue': (v: ProfileQuestionAnswer.Data.DateComponents['value']) => typeof v === 'object' || v === undefined
    }
})
export default class DateSelect extends Vue {
    readonly maxDaysInMonth = 31;
    readonly currentYear = new Date().getFullYear();
    readonly monthOptions = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    attributeName = `dateSelect-${Math.floor(Math.random() * 1000000)}`;
    components!: AttributeView.DateComponentsAttribute['components'];
    modelValue!: ProfileQuestionAnswer.Data.DateComponents['value'];
    modelDay: number | null = null;
    modelMonth: number | null = null;
    modelYear: number | null = null;
    daysInSelectedMonth = this.maxDaysInMonth;
    isOptional?: boolean;

    @Watch('modelValue')
    modelValueChange(newModelValue: ProfileQuestionAnswer.Data.DateComponents['value']): void {
        if (newModelValue && !this.modelDay && !this.modelYear && !this.modelMonth) {
            this.setModelValues(newModelValue);
        }
    }

    created(): void {
        if (this.modelValue) {
            this.setModelValues(this.modelValue);
        }
    }

    get dayOptions(): number[] {
        return Array.from({ length: this.daysInSelectedMonth }, (_value, key) => key + 1);
    }

    get yearOptions(): number[] {
        const firstYear = 1900;
        const lastYear = 2100;
        const yearRange = lastYear - firstYear;
        return Array.from({ length: yearRange + 1 }, (_value, key) => key + firstYear);
    }

    get allowDaySelect(): boolean {
        return this.components.includes('day');
    }

    get allowMonthSelect(): boolean {
        return this.components.includes('month');
    }

    get allowYearSelect(): boolean {
        return this.components.includes('year');
    }

    setModelValues(modelValue: ProfileQuestionAnswer.Data.DateComponents['value']): void {
        const { day, month, year } = modelValue;
        this.modelDay = day ?? null;
        this.modelMonth = month ?? null;
        this.modelYear = year ?? null;
    }

    onDateChange(): void {
        const defaultYear = 4; // Default to a leap year to make sure February 29 can be selected when year is unspecified
        this.daysInSelectedMonth = this.modelMonth
            ? new Date(this.modelYear ?? defaultYear, this.modelMonth, 0).getDate()
            : this.maxDaysInMonth;

        if (this.modelDay && this.modelDay > this.daysInSelectedMonth) {
            this.modelDay = this.daysInSelectedMonth;
        }

        let modelValue: ProfileQuestionAnswer.Data.DateComponents['value'] | undefined = {
            day: this.modelDay ?? undefined,
            month: this.modelMonth ?? undefined,
            year: this.modelYear ?? undefined
        };

        if (!(this.modelDay || this.modelMonth || this.modelYear)) {
            modelValue = undefined;
        }

        this.$emit('update:modelValue', modelValue);
    }
}
