export function setupDeviceFingerprintScript(): void {
    window.IGLOO = window.IGLOO ?? {
        'enable_rip': true,
        'enable_flash': false,
        'install_flash': false,
        'loader': {
            'version': 'general5',
            'fp_static': false
        }
    };

    if (document.getElementById('iovation_script')) {
        return;
    }

    const js = document.createElement('script');
    js.id = 'iovation_script';
    js.src = '/script/iovation_loader_5x.js';
    document.head.appendChild(js);
}

export function getFingerprint(): string | undefined {
    if (typeof window.IGLOO?.getBlackbox === 'function') {
        const bbData = window.IGLOO.getBlackbox();
        if (bbData.finished) {
            return bbData.blackbox;
        }
    }
}
