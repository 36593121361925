
import { Options, Vue } from 'vue-class-component';
import { AttributeView, ProfileQuestionAnswer } from '@okendo/reviews-common';
import { Watch } from 'vue-property-decorator';

import SingleTag from '@/shared-components/input-components/SingleTag.vue';
import MultiTag from '@/shared-components/input-components/MultiTag.vue';
import CentredRange from '@/shared-components/input-components/CentredRange.vue';
import RangeInput from '@/shared-components/input-components/Range.vue';
import DateSelect from '@/shared-components/input-components/DateSelect.vue';
import LocationSelect from '@/shared-components/input-components/LocationSelect.vue';
import { CountryZone, getCountries, getCountryZones } from '@/utils/countriesUtil';
import Tooltip from '@/shared-components/Tooltip.vue';
import Icon from '@/shared-components/Icon.vue';

@Options({
    components: {
        SingleTag,
        MultiTag,
        RangeInput,
        CentredRange,
        DateSelect,
        LocationSelect,
        Icon,
        Tooltip
    },
    props: {
        modelValue: [String, Array, Object],
        hasError: Boolean,
        errorMessage: {
            type: String,
            default: 'Please select an option'
        },
        attribute: Object,
        countryCode: String
    },
    emits: {
        'update:modelValue': (v: AttributeValue | undefined) => {
            return !v
                || typeof v === 'string'
                || (Array.isArray(v) && v.every(x => typeof x === 'string'))
                || (typeof v === 'object' && ('countryCode' in v && typeof v.countryCode === 'string'))
                || (typeof v === 'object' && ('day' in v && (typeof v.day === 'number' || typeof v.month === 'number' || typeof v.year === 'number')));
        }
    }
})
export default class Attribute extends Vue {
    modelValue?: AttributeValue;
    attribute!: AttributeView.DataAttribute;
    countryCode?: string;

    @Watch('countryCode')
    countryCodeChange(): void {
        this.inputModel = undefined;
    }

    get inputModel(): AttributeValue | undefined {
        return this.modelValue;
    }

    set inputModel(value: AttributeValue | undefined) {
        this.$emit('update:modelValue', value);
    }

    get countryZone(): CountryZone | undefined {
        return getCountryZones().find(zone => zone.countryCode === this.countryCode);
    }

    get isZone(): boolean {
        return this.attribute.type === 'drop-down' && this.attribute.dataSource === 'zone';
    }

    get isEmptyZone(): boolean {
        if (this.isZone && !this.countryZone) {
            this.inputModel = undefined;
            return true;
        }

        return false;
    }

    get title(): string | undefined {
        if (this.isZone) {
            return this.countryZone?.zoneDisplayName;
        }

        return this.attribute.title;
    }

    get subtitle(): string | undefined {
        if (this.attribute.type === 'single-value') {
            return this.$t('Choose one');
        }
        else if (this.attribute.type === 'multi-value') {
            const optionCount = this.attribute.options?.length || 0;
            return optionCount > 5
                ? this.$t('Choose up to 5 options')
                : this.$t('Choose all that apply');
        }
    }

    get dropdownOptions(): DropdownOption[] | undefined {
        if (this.attribute.type !== 'drop-down') {
            return;
        }

        switch (this.attribute.dataSource) {
            case 'country':
                return getCountries();
            case 'zone':
                return this.countryZone?.zones;
            default:
                return this.attribute.options?.map(option => ({
                    displayName: option,
                    value: option
                }));
        }
    }
}

type AttributeValue = string | string[] | ProfileQuestionAnswer.Data.Location['value'] | ProfileQuestionAnswer.Data.DateComponents['value'];
interface DropdownOption {
    displayName: string;
    value: string;
}
