import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e7b55fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-mediaUploader-indicator" }
const _hoisted_2 = ["disabled", "aria-label"]
const _hoisted_3 = {
  key: 0,
  for: "mediaUploaderInput",
  class: "c-mediaUploader-label u-paddingUI--xxSmall"
}
const _hoisted_4 = { class: "c-mediaUploader-label-icon" }
const _hoisted_5 = { class: "c-mediaUploader-label-body u-paddingLeftUI--xSmall" }
const _hoisted_6 = { class: "u-fontWeight--bold" }
const _hoisted_7 = { class: "c-mediaUploader-label-body-subtitle" }
const _hoisted_8 = {
  key: 1,
  class: "c-mediaBlocksContainer"
}
const _hoisted_9 = ["src", "alt"]
const _hoisted_10 = ["onLoadeddata", "hidden", "height", "width"]
const _hoisted_11 = ["src"]
const _hoisted_12 = ["hidden"]
const _hoisted_13 = {
  key: 2,
  class: "c-loader c-loader--small c-loader--iconColor"
}
const _hoisted_14 = ["onClick", "aria-label"]
const _hoisted_15 = {
  key: 0,
  for: "mediaUploaderInput",
  class: "c-mediaBlock c-mediaBlock--addMedia"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _directive_analytics = _resolveDirective("analytics")!
  const _directive_prevent_click_in_preview = _resolveDirective("prevent-click-in-preview")!

  return (_openBlock(), _createElementBlock("div", {
    onDragenter: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.mediaDrag && _ctx.mediaDrag(...args)), ["prevent","stop"])),
    onDragover: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.mediaDrag && _ctx.mediaDrag(...args)), ["prevent","stop"])),
    onDragleave: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.mediaDragLeave && _ctx.mediaDragLeave(...args)), ["prevent","stop"])),
    onDrop: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.mediaDrop && _ctx.mediaDrop(...args)), ["prevent","stop"])),
    class: _normalizeClass([{
            isHighlighted: _ctx.isUploaderHighlighted,
            isEmpty: !_ctx.modelValue?.length
        }, "c-mediaUploader"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.uploadingMediaIds.length)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onTransitionend: _cache[0] || (_cache[0] = ($event: any) => (_ctx.indicatorTransitionEnded($event))),
            style: _normalizeStyle({
                    width: `${_ctx.mediaUploadProgress}%`
                }),
            class: "c-mediaUploader-indicator-bar"
          }, null, 36))
        : _createCommentVNode("", true)
    ]),
    _withDirectives(_createElementVNode("input", {
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onMediaFilesChange($event.target.files))),
      disabled: !_ctx.isMediaCountBelowLimit,
      "aria-label": `${_ctx.$t('Add Photos or Videos')}, ${_ctx.clickToUploadText}`,
      id: "mediaUploaderInput",
      class: "c-mediaUploader-input",
      accept: "image/*, video/*",
      type: "file",
      multiple: ""
    }, null, 40, _hoisted_2), [
      [_directive_analytics, { eventName: 'click-upload-media' }],
      [_directive_prevent_click_in_preview]
    ]),
    (!_ctx.modelValue?.length)
      ? (_openBlock(), _createElementBlock("label", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_icon, {
              icon: "photo-video",
              class: "c-icon--media c-icon--component"
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('Add Photos or Videos')), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.clickToUploadText), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.modelValue?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (mediaBlock) => {
            return (_openBlock(), _createElementBlock("div", {
              key: mediaBlock.streamId,
              class: "c-mediaBlock"
            }, [
              (mediaBlock.type === 'image' && mediaBlock.progress > 99)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: mediaBlock.src,
                    alt: mediaBlock.name,
                    class: "c-mediaBlock-img"
                  }, null, 8, _hoisted_9))
                : _createCommentVNode("", true),
              (mediaBlock.type === 'video')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("video", {
                      onLoadeddata: ($event: any) => (_ctx.videoLoaded($event, mediaBlock)),
                      muted: true,
                      hidden: mediaBlock.progress < 100,
                      height: _ctx.thumbnailWidth,
                      width: _ctx.thumbnailWidth,
                      class: "c-mediaBlock-video",
                      preload: "metadata",
                      playsInline: "",
                      autoplay: "",
                      loop: ""
                    }, [
                      _createElementVNode("source", {
                        src: mediaBlock.src
                      }, null, 8, _hoisted_11)
                    ], 40, _hoisted_10),
                    _createElementVNode("div", {
                      hidden: mediaBlock.progress < 100,
                      class: "c-mediaBlock-video-duration"
                    }, _toDisplayString(mediaBlock.duration), 9, _hoisted_12)
                  ], 64))
                : _createCommentVNode("", true),
              (mediaBlock.progress < 100)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13))
                : _createCommentVNode("", true),
              _withDirectives(_createElementVNode("button", {
                onClick: _withModifiers(($event: any) => (_ctx.removeMedia(mediaBlock.streamId)), ["prevent"]),
                "aria-label": _ctx.$t('Remove Media'),
                type: "button",
                class: "c-mediaBlock-remove"
              }, [
                _createVNode(_component_icon, {
                  icon: "close",
                  class: "c-icon--removeMedia"
                })
              ], 8, _hoisted_14), [
                [_directive_analytics, { eventName: 'click-remove-media' }]
              ])
            ]))
          }), 128)),
          (_ctx.isMediaCountBelowLimit)
            ? (_openBlock(), _createElementBlock("label", _hoisted_15, [
                _createVNode(_component_icon, {
                  icon: "plus",
                  class: "c-icon--addMedia"
                })
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 34))
}