
import { Options, Vue } from 'vue-class-component';

import store from '@/store';
import Bubble from '@/shared-components/Bubble.vue';
import { ReviewerProfile } from '@/store/modules/profile';
import { StoreMethod } from '@/store/storeTypings';
import { postAnalytics } from '@/utils/api';
import { resizeGoogleProfileImage } from '@/utils/googleProfileUtil';

@Options({
    components: {
        Bubble
    },
    emits: ['signedIn'],
    store
})
export default class SocialLogin extends Vue {
    fbNeedsAskEmailPermission = false;
    showAskEmailPermission = false;
    showFacebookLogin = true;
    fbLoginStatus = '';
    fbAccessToken = '';

    created() {
        window.handleCredentialResponse = (response: GoogleCredentialResponse) => this.handleGoogleCredentialResponse(response);
    }

    get profile() {
        return store.state.profile.reviewerProfile;
    }

    get isReviewEnhancementPage(): boolean {
        return this.$route.name === 'Review Enhancement';
    }

    get localeCode(): string {
        return store.state.subscriber.locale.code;
    }

    updateProfile(reviewerProfile: Partial<ReviewerProfile>) {
        this.$emit('signedIn');

        postAnalytics({
            eventName: 'action-logged-in',
            label: 'socialMediaType',
            value: reviewerProfile.socialMediaType
        });

        // Don't update email on Review Enhancement page
        if (this.isReviewEnhancementPage) {
            reviewerProfile.email = store.state.profile.reviewerProfile.email;
        }

        store.commit<StoreMethod>({
            type: 'profile/UPDATE_IS_LOGGED_IN',
            isLoggedIn: true
        });
        store.commit<StoreMethod>({
            type: 'profile/UPDATE_REVIEWER_PROFILE',
            reviewerProfile
        });
    }

    mounted() {
        this.setupSocialMedia();
    }

    get askEmailPermission(): string {
        return this.$t('Email address is required to submit your review. Please try again, or continue as guest');
    }

    async setupSocialMedia(): Promise<void> {
        try {
            this.setFacebookAsyncInit();
            await this.loadFacebookSDK();
            await this.setFacebookLoginStatus();
        }
        catch {
            this.showFacebookLogin = false;
        }

        await this.loadGoogleSDK();
        await this.initializeGoogle();
    }

    async setFacebookLoginStatus(): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                window.FB.getLoginStatus((statusResponse: fb.StatusResponse) => {
                    this.fbLoginStatus = statusResponse.status;

                    if (this.fbLoginStatus === 'connected') {
                        window.FB.api(
                            '/me/permissions',
                            (permissionResponse: PermissionResponse) => {
                                const isEmailGranted = permissionResponse.data.some(
                                    data => data.permission === 'email' && data.status === 'granted'
                                );

                                if (isEmailGranted) {
                                    this.fbAccessToken = statusResponse.authResponse.accessToken;
                                }
                                else {
                                    this.fbNeedsAskEmailPermission = true;
                                }

                                resolve();
                            }
                        );
                    }
                    else {
                        resolve();
                    }
                });
            }
            catch (e) {
                reject(e);
            }
        });
    }

    async facebookLogin(): Promise<void> {
        if (this.fbLoginStatus === 'connected') {
            if (this.fbNeedsAskEmailPermission) {
                window.FB.login(
                    (response: fb.StatusResponse) => {
                        if (response.status === 'connected') {
                            this.handleFacebookLoginResponse(response);
                        }
                    },
                    {
                        scope: 'email',
                        return_scopes: true,
                        auth_type: 'rerequest'
                    }
                );
            }
            else {
                this.getProfileFromFacebook(this.fbAccessToken);
            }
        }
        else {
            window.FB.login(
                (response: fb.StatusResponse) => {
                    if (response.status === 'connected') {
                        this.handleFacebookLoginResponse(response);
                    }
                },
                { scope: 'email', return_scopes: true }
            );
        }
    }

    handleFacebookLoginResponse(response: fb.StatusResponse): void {
        if (response) {
            const isEmailGranted = response.authResponse.grantedScopes?.includes('email');

            if (isEmailGranted) {
                this.getProfileFromFacebook(response.authResponse.accessToken);
            }
            else {
                this.showAskEmailPermission = true;
            }
        }
    }

    getProfileFromFacebook(accessToken: string): void {
        window.FB.api(
            '/me',
            {
                fields: 'picture.width(1024).height(1024).redirect(false),name,email',
                access_token: accessToken
            },
            (userProfileResponse: FacebookUserProfileResponse) => {
                const { name, email, picture, id } = userProfileResponse;
                const imageUrl = picture?.data.url;

                this.updateProfile({
                    name,
                    email,
                    imageUrl,
                    socialMediaType: 'facebook',
                    socialMediaUserId: id
                });
            }
        );
    }

    async setFacebookAsyncInit() {
        window.fbAsyncInit = () => {
            window.FB.init({
                appId: process.env.VUE_APP_FACEBOOK_APPID,
                cookie: true,
                xfbml: true,
                version: 'v11.0'
            });
        };
    }

    async initializeGoogle(): Promise<void> {
        if (!window.google) {
            return;
        }

        const idConfiguration = {
            client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
            callback: this.handleGoogleCredentialResponse
        };
        await window.google.accounts.id.initialize(idConfiguration);

        this.renderGoogleButton();
    }

    handleGoogleCredentialResponse(response: GoogleCredentialResponse): void {
        const parsedCredentials: GoogleParsedCredential = this.parseGoogleCredential(response.credential);
        const { name, email, picture, sub } = parsedCredentials;

        this.updateProfile({
            name,
            email,
            imageUrl: picture?.length ? resizeGoogleProfileImage(picture) : undefined,
            socialMediaType: 'google',
            socialMediaUserId: sub
        });
    }

    parseGoogleCredential(credential: string): GoogleParsedCredential {
        // https://stackoverflow.com/a/38552302
        const base64Url = credential.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('')
            .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join(''));

        return JSON.parse(jsonPayload);
    }

    renderGoogleButton(): void {
        if (!window.google) {
            return;
        }

        const sendAnalytics = () => postAnalytics({
            eventName: 'click-login',
            label: 'socialMediaType',
            value: 'google'
        });

        const button = this.$refs.googleSignInButton as HTMLElement;
        const buttonConfiguration = {
            type: 'standard',
            text: 'continue_with',
            locale: this.localeCode,
            click_listener: sendAnalytics
        };

        window.google.accounts.id.renderButton(button, buttonConfiguration);
    }

    async loadFacebookSDK() {
        await this.loadScript('facebook', 'https://connect.facebook.net/en_US/sdk.js');
    }

    async loadGoogleSDK(): Promise<void> {
        await this.loadScript('google', 'https://accounts.google.com/gsi/client');
    }

    async loadScript(name: string, source: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const id = `${name}-jssdk`;

            if (document.getElementById(id)) {
                resolve;
            }

            const firstScriptTag = document.getElementsByTagName('script')[0];
            const js = document.createElement('script');
            js.id = id;
            js.src = source;
            js.onload = resolve;
            js.onerror = reject;
            firstScriptTag.parentNode!.insertBefore(js, firstScriptTag);
        });
    }
}

interface PermissionResponse {
    data: PermissionData[];
}

interface PermissionData {
    permission: string;
    status: 'granted' | 'declined';
}

interface FacebookUserProfileResponse {
    id: string;
    name: string;
    email: string;
    picture?: {
        data: {
            url: string;
        };
    };
}

interface GoogleParsedCredential {
    email: string;
    name: string; // 'Firstname Lastname',
    picture?: string; // 'https: //lh3.googleusercontent.com/a/alphanumeric-alphanumeric=s96-c',
    sub: string; // Unique Google Account ID: a string of 20-30ish digits
}
