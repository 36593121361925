import { Media } from '@okendo/reviews-common';
import { uuid } from 'vue-uuid';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export class ProfileModule extends VuexModule {
    isLoggedIn = false;
    isOptedIn = false;
    reviewerProfile: ReviewerProfile = {};
    sessionId: string = uuid.v4();

    @Mutation
    UPDATE_IS_LOGGED_IN({ isLoggedIn }: UpdateIsLoggedIn) {
        this.isLoggedIn = isLoggedIn;
    }

    @Mutation
    UPDATE_IS_OPTED_IN({ isOptedIn }: UpdateIsOptedIn) {
        this.isOptedIn = isOptedIn;
    }

    @Mutation
    UPDATE_REVIEWER_PROFILE({ reviewerProfile }: UpdateReviewerProfile) {
        this.reviewerProfile = {
            ...this.reviewerProfile,
            ...reviewerProfile
        };
    }

    @Mutation
    CLEAR_PROFILE() {
        this.reviewerProfile = {
            name: this.reviewerProfile.name,
            email: this.reviewerProfile.email
        };
        this.isLoggedIn = false;
    }

    @Mutation
    CLEAR_CUSTOM_AVATAR() {
        this.reviewerProfile.imageUrl = undefined;
        this.reviewerProfile.customAvatar = undefined;
        this.reviewerProfile.customAvatarSource = undefined;
    }
}

export type ProfileModuleMethod = UpdateReviewerProfile
    | UpdateIsLoggedIn
    | UpdateIsOptedIn
    | ClearProfile
    | ClearCustomAvatar;

type ModulePrefix = 'profile';

interface UpdateIsLoggedIn {
    type: `${ModulePrefix}/UPDATE_IS_LOGGED_IN`;
    isLoggedIn: boolean;
}

interface UpdateIsOptedIn {
    type: `${ModulePrefix}/UPDATE_IS_OPTED_IN`;
    isOptedIn: boolean;
}

interface UpdateReviewerProfile {
    type: `${ModulePrefix}/UPDATE_REVIEWER_PROFILE`;
    reviewerProfile: ReviewerProfile;
}

interface ClearProfile {
    type: `${ModulePrefix}/CLEAR_PROFILE`;
}

interface ClearCustomAvatar {
    type: `${ModulePrefix}/CLEAR_CUSTOM_AVATAR`;
}

export type SocialMediaType = '' | 'facebook' | 'google';

export interface ReviewerProfile {
    name?: string;
    countryCode?: string;
    email?: string;
    socialMediaType?: SocialMediaType;
    socialMediaUserId?: string;
    imageUrl?: string;
    customAvatar?: Media.CustomAvatar;
    customAvatarSource?: string | ArrayBuffer;
}
