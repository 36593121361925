import { DirectiveBinding } from 'vue';

import { postAnalytics } from './api';

// Example Directive Usage:
// v-analytics="{ eventName: 'string', label: 'string', value: 'string' }"
// v-analytics:change="{ eventName: 'string', label: 'string' }"

const listenerOptions = { capture: false, passive: true };

export default {
    mounted: (element: HTMLElement, binding: DirectiveBinding<AnalyticsEvent>) => {
        const { arg: eventType, value: { eventName, label, value } } = binding;

        if (eventType === 'change') {
            element.addEventListener('change', ({ target }: Event) => {
                const elementValue = value ?? (target as HTMLInputElement).value.length;
                postAnalytics({ eventName, value: elementValue, label });
            }, listenerOptions);
        }
        else {
            element.addEventListener('click', () => {
                postAnalytics({ eventName, value, label });
            }, listenerOptions);
        }
    }
};

export function pageCloseAnalytics(): void {
    document.addEventListener('visibilitychange', () => {
        postAnalytics({
            eventName: 'page-visibility-change',
            label: 'visibility',
            value: document.visibilityState
        });
    });

    window.addEventListener('beforeunload', () => {
        postAnalytics({ eventName: 'page-close' });
    });
}

export interface AnalyticsEvent {
    eventName: string;
    label?: string;
    value?: string | string[] | number;
}
