import { ReviewsAPIPublic } from '@okendo/reviews-common';

import store from '@/store';
import { StoreMethod } from '@/store/storeTypings';

export function setProfileAndOrderDetails(reviewRequestId: string, reviewRequest: ReviewsAPIPublic.ReviewRequest.ReviewRequestId.Get.Response): void {
    const { name: nameInActor, countryCode, email: emailInActor, remainingProductsToReview, reviewed } = reviewRequest;
    const reviewedProductIds = reviewed.map(reviewIdProductIdMap => reviewIdProductIdMap.productId);
    const { review, reviewerName } = store.state.review;

    const name = reviewerName ?? nameInActor;
    const avatarUrl = review?.reviewer.avatarUrl;
    const previewMode = store.state.subscriber.previewMode;

    store.commit<StoreMethod>({
        type: 'profile/UPDATE_REVIEWER_PROFILE',
        reviewerProfile: {
            countryCode,
            email: emailInActor,
            name,
            imageUrl: avatarUrl
        }
    });

    store.commit<StoreMethod>({
        type: 'profile/UPDATE_IS_LOGGED_IN',
        isLoggedIn: !!(name && emailInActor && !previewMode)
    });

    store.commit<StoreMethod>({
        type: 'order/UPDATE_ORDER',
        remainingProductsToReview,
        reviewedProductIds,
        reviewRequestId
    });
}
