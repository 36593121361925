
import { ReviewsAPIPublic, StyleSettings, HeaderImageWithStyle } from '@okendo/reviews-common';
import { english, getLocale } from '@okendo/translations-common';
import { AxiosResponse } from 'axios';
import { Options, Vue } from 'vue-class-component';
import { NavigationFailure } from 'vue-router';

import Alert from '@/shared-components/Alert.vue';
import NotificationBar from '@/shared-components/NotificationBar.vue';
import ViewState, { ViewStateStatus } from '@/shared-components/ViewState.vue';
import store from '@/store';
import { getProduct, getReviewById, getReviewRequestDetails, getSettings, postErrorAnalytics, postRating } from '@/utils/api';
import { enableBackgroundColor, setRecorderStyling } from '@/utils/recorderStyling';
import { isQuestionRoute } from '@/utils/routerUtils';
import { StoreMethod } from './store/storeTypings';
import { formatQueryStrings, QueryStrings } from './utils/vueUtils';
import { setLanguage } from './utils/i18nUtil';
import Modal, { BaseModalResponse } from '@/shared-components/Modal.vue';
import { updatePageTitle } from '@/utils/productUtils';
import { usePromisedModal } from '@/utils/usePromisedModal';
import { ErrorCode, getErrorCodeFromError, notFound, unauthorized } from '@/utils/errorUtils';
import { setProfileAndOrderDetails } from '@/utils/moduleHelpers';
import { loadFont } from '@/utils/fontUtil';
import { pageCloseAnalytics } from './utils/analyticsUtil';
import { checkForLocationAttributes } from './utils/countriesUtil';
import { updateRewardPrompt } from './utils/rewardUtils';
import { setupSurveyPreview } from './utils/surveyUtil';
import { isEmailAddress } from './utils/validationUtil';
import { ReviewerProfile } from './store/modules/profile';
import eventBus from './utils/mittUtil';

@Options({
    components: {
        Alert,
        Modal,
        ViewState,
        NotificationBar
    },
    store
})
export default class App extends Vue {
    viewState: ViewStateStatus = 'skeleton';
    errorCode: ErrorCode = 'unknown';
    erroredApiCalls: string[] = [];
    testModeModal = usePromisedModal<BaseModalResponse>();
    headerImage?: HeaderImageWithStyle;
    headerImageLink?: string;
    queryStrings?: QueryStrings;

    get storeName(): string | undefined {
        return store.state.subscriber.storeName;
    }

    get isPreviewMode(): boolean {
        return store.state.subscriber.previewMode;
    }

    get previewMessage(): string {
        const message = 'This preview includes sample data and has reduced interactivity - use as a guide only.';
        const reviewSpecific = ` To test the full review capture process, click the ‘Test Review Capture’ button on any product page.`;
        return `${message}${isQuestionRoute() ? '' : reviewSpecific}`;
    }

    data(): Record<string, undefined> {
        return {
            headerImage: undefined,
            headerImageLink: undefined
        };
    }

    created(): void {
        pageCloseAnalytics();
        this.startInitialisation();
    }

    async startInitialisation(): Promise<void> {
        try {
            await this.$router.isReady();
            this.handleQueryStrings();
            this.setupPreviewMode();

            await this.loadData([
                this.setProductDetails(),
                this.setReviewRequestDetails(),
                this.setCustomSettings()
            ]);
        }
        catch (error) {
            this.setErrorState(error);
        }
    }

    async finishInitialisation(): Promise<void> {
        try {
            updatePageTitle();
            await this.checkProductReviewedState();
            this.verifyReviewRequestDetails();
            await this.sendRating();

            this.viewState = 'content';
            enableBackgroundColor();
        }
        catch (error) {
            this.setErrorState(error);
        }
    }

    setErrorState(error: unknown): void {
        postErrorAnalytics(error);
        this.errorCode = getErrorCodeFromError(error);
        this.viewState = 'error';
        enableBackgroundColor();
    }

    async loadData(apiCalls: Promise<void>[]): Promise<void> {
        this.viewState = 'skeleton';
        this.erroredApiCalls = [];

        const apiResults = await Promise.allSettled(apiCalls);

        const rejectedResults = apiResults.filter(result => result.status === 'rejected') as PromiseRejectedResult[];
        if (rejectedResults.length) {
            throw rejectedResults.map(error => error.reason);
        }
        else {
            this.finishInitialisation();
        }
    }

    async retryApiCalls(): Promise<void> {
        const apiCallsToRetry = [];
        if (this.erroredApiCalls.includes('setReviewRequestDetails')) {
            apiCallsToRetry.push(this.setReviewRequestDetails());
        }
        if (this.erroredApiCalls.includes('setProductDetails')) {
            apiCallsToRetry.push(this.setProductDetails());
        }
        if (this.erroredApiCalls.includes('setCustomSettings')) {
            apiCallsToRetry.push(this.setCustomSettings());
        }

        try {
            await this.loadData(apiCallsToRetry);
        }
        catch (error) {
            this.setErrorState(error);
        }
    }

    handleQueryStrings(): void {
        this.queryStrings = formatQueryStrings(this.$route.query);
        const {
            editToken,
            elementId,
            email,
            emailVerificationToken,
            name,
            previewMode,
            productId,
            rating,
            reviewId,
            reviewInvitationId,
            reviewRequestId,
            subscriberId,
            testMode,
            verificationCode
        } = this.queryStrings;

        const ratingNumber = parseInt(rating, 10) || 0;

        if (reviewInvitationId) {
            setRecorderStyling();
            throw unauthorized({ errorCode: 'review-link-expired' });
        }

        if (!subscriberId || !productId) {
            setRecorderStyling();
            throw notFound({ errorCode: 'product-not-found' });
        }

        store.commit<StoreMethod>({
            type: 'subscriber/UPDATE_SUBSCRIBER_ID',
            subscriberId
        });

        if (ratingNumber >= 0 && ratingNumber <= 5) {
            store.commit<StoreMethod>({
                type: 'reviewForm/UPDATE_RATING',
                rating: ratingNumber.toString()
            });
        }

        store.commit<StoreMethod>({
            type: 'verification/UPDATE_VERIFICATION_CODE',
            verificationCode
        });

        store.commit<StoreMethod>({
            type: 'verification/UPDATE_REVIEW_ID',
            reviewId
        });

        if (name || email) {
            const sanitizedEmail = email?.trim();
            const reviewerProfile: ReviewerProfile = {
                name: name?.trim(),
                email: isEmailAddress(sanitizedEmail) ? sanitizedEmail : undefined
            };

            store.commit<StoreMethod>({
                type: 'profile/UPDATE_REVIEWER_PROFILE',
                reviewerProfile
            });

            store.commit<StoreMethod>({
                type: 'profile/UPDATE_IS_LOGGED_IN',
                isLoggedIn: !!(reviewerProfile.name && reviewerProfile.email)
            });
        }

        if (editToken) {
            store.commit<StoreMethod>({
                type: 'review/UPDATE_EDIT_TOKEN',
                editToken
            });
        }

        if (testMode) {
            store.commit<StoreMethod>({
                type: 'order/UPDATE_ORDER',
                reviewRequestId: 'test'
            });

            this.testModeModal.openModal();
        }
        else {
            store.commit<StoreMethod>({
                type: 'order/UPDATE_ORDER',
                reviewRequestId,
                elementId
            });
        }

        if (previewMode) {
            store.commit<StoreMethod>({
                type: 'subscriber/UPDATE_PREVIEW_MODE',
                previewMode: true
            });

            setupSurveyPreview();
        }

        if (emailVerificationToken) {
            store.commit<StoreMethod>({
                type: 'verification/UPDATE_EMAIL_VERIFICATION_TOKEN',
                emailVerificationToken
            });
        }
    }

    async setReviewRequestDetails(): Promise<void> {
        const { reviewRequestId } = store.state.order;
        if (!reviewRequestId) {
            return;
        }

        try {
            const reviewRequest = await getReviewRequestDetails(reviewRequestId);
            const { reviewed } = reviewRequest;
            const productId = this.queryStrings?.productId;
            if (!productId) {
                return;
            }

            store.commit<StoreMethod>({
                type: 'siteReview/UPDATE_HAS_SITE_REVIEW',
                hasSiteReview: reviewRequest.hasSiteReview
            });

            const reviewId = reviewed.find(reviewIdProductIdMap => reviewIdProductIdMap.productId === productId)?.reviewId;
            if (reviewId) {
                const { review } = await getReviewById(reviewRequestId, reviewId);
                const { reviewer: { name: reviewerName } } = review;

                store.commit<StoreMethod>({
                    type: 'review/UPDATE_REVIEW',
                    review,
                    reviewerName,
                    reviewed
                });
            }

            setProfileAndOrderDetails(reviewRequestId, reviewRequest);
            await updateRewardPrompt();
        }
        catch (error) {
            this.erroredApiCalls.push('setReviewRequestDetails');
            throw error;
        }
    }

    async setProductDetails(): Promise<void> {
        const productId = this.queryStrings?.productId ?? '';

        try {
            const { product } = await getProduct(productId);
            store.commit<StoreMethod>({
                type: 'product/UPDATE_PRODUCT',
                product
            });

            await checkForLocationAttributes(product);
        }
        catch (error) {
            this.erroredApiCalls.push('setProductDetails');
            throw error;
        }
    }

    updateStyleSettings(settings: StyleSettings.RecorderPlus | undefined): void {
        if (settings) {
            setRecorderStyling(settings);
            this.headerImage = settings.headerImage;
            this.headerImageLink = settings.headerImageLinkUrl;

            store.commit<StoreMethod>({
                type: 'banner/UPDATE_IS_BANNER_EXPANDED',
                isRewardsBannerExpanded: !!settings.isRewardsBannerExpanded
            });

            store.commit<StoreMethod>({
                type: 'settings/UPDATE_SETTINGS',
                settings
            });
        }
        else {
            setRecorderStyling();
        }
    }

    async setCustomSettings(): Promise<void> {
        try {
            const { reviewCaptureSettings } = await getSettings();
            const { allowAnonymous, currencySymbol, styling, localeAndVariant: subscriberLocale, store: storeDetails } = reviewCaptureSettings;
            const overrideLocale = getLocale(this.queryStrings?.locale, this.queryStrings?.variant);
            const { settings } = store.state.settings;

            if (!Object.keys(settings).length) {
                this.updateStyleSettings(styling);
            }

            await setLanguage(overrideLocale ?? subscriberLocale ?? english);

            store.commit<StoreMethod>({
                type: 'subscriber/UPDATE_STORE_NAME',
                storeName: storeDetails.name
            });

            store.commit<StoreMethod>({
                type: 'subscriber/UPDATE_STORE_URL',
                storeUrl: storeDetails.url
            });

            store.commit<StoreMethod>({
                type: 'subscriber/UPDATE_ALLOW_ANONYMOUS',
                allowAnonymous
            });

            if (storeDetails.avatarImageUrl) {
                store.commit<StoreMethod>({
                    type: 'subscriber/UPDATE_AVATAR_URL',
                    avatarImageUrl: storeDetails.avatarImageUrl
                });
            }

            store.commit<StoreMethod>({
                type: 'subscriber/UPDATE_CURRENCY_SYMBOL',
                currencySymbol
            });

            await loadFont();
        }
        catch (error) {
            setRecorderStyling();
            this.erroredApiCalls.push('setCustomSettings');
            throw error;
        }
    }

    verifyReviewRequestDetails(): void {
        const { reviewRequestId, remainingProductsToReview, elementId } = store.state.order;
        const { allowAnonymous } = store.state.subscriber;
        const { product } = store.state.product;

        if (this.queryStrings?.testMode || this.queryStrings?.previewMode || isQuestionRoute()) {
            return;
        }

        if (reviewRequestId) {
            if (!elementId) {
                throw unauthorized({ errorCode: 'anonymous-reviews-disabled' });
            }
            if (this.$route.name === 'Review' && !remainingProductsToReview?.some(remainingProduct => remainingProduct.productId === product.productId)) {
                throw unauthorized({ errorCode: 'anonymous-reviews-disabled' });
            }
        }
        else if (!allowAnonymous) {
            throw unauthorized({ errorCode: 'anonymous-reviews-disabled' });
        }
    }

    async sendRating(): Promise<void> {
        const { rating } = store.state.reviewForm;
        const { reviewRequestId } = store.state.order;
        const emailAddress = store.state.profile.reviewerProfile.email?.trim();
        const { product, isPreviouslyReviewed } = store.state.product;

        if (isPreviouslyReviewed || rating === '0' || !emailAddress || !reviewRequestId || reviewRequestId === 'test') {
            return;
        }

        const ratingToPost: ReviewsAPIPublic.Products.ProductId.Rate.Request = {
            rating,
            emailAddress,
            reviewRequestId
        };

        try {
            await postRating(product.productId, ratingToPost);
        }
        catch (error) {
            // 403 status returned when product is already rated
            if ((error as AxiosResponse | undefined)?.status !== 403) {
                throw error;
            }
        }
    }

    async checkProductReviewedState(): Promise<void | NavigationFailure> {
        const {
            order: { reviewRequestId, reviewedProductIds },
            product: { product }
        } = store.state;

        if (!reviewRequestId) {
            return;
        }

        if (reviewedProductIds?.includes(product.productId)) {
            store.commit<StoreMethod>({
                type: 'product/UPDATE_PREVIOUSLY_REVIEWED',
                isPreviouslyReviewed: true
            });

            if (this.$route.name === 'Review') {
                await this.$router.replace({
                    path: 'post-review',
                    query: this.$route.query
                });
            }
        }
    }

    setupPreviewMode(): void {
        const previewMode = store.state.subscriber.previewMode;

        if (!previewMode) {
            return;
        }

        const iframeScript = document.createElement('script');
        iframeScript.src = '/script/iframeResizer.contentWindow.min.js';
        document.head.appendChild(iframeScript);

        const adminAppUrl = process.env.VUE_APP_ADMIN_APP_URL;
        window.addEventListener('message', event => {
            if (event.origin === adminAppUrl) {
                if (event.data?.iframeTemplatePreviewer?.type === 'update') {
                    const styleSettings: StyleSettings.RecorderPlus = event.data.iframeTemplatePreviewer.content;
                    this.updateStyleSettings(styleSettings);
                    loadFont();
                    eventBus.emit('adminIframeUpdate');
                }
                if (event.data?.iframeTemplatePreviewer?.type === 'route') {
                    const route = event.data.iframeTemplatePreviewer.route;
                    this.$router.push({
                        path: route,
                        query: {
                            ...this.$route.query
                        }
                    });
                }
            }
        });

        if (window.opener) {
            window.opener.postMessage('recorder-loaded', adminAppUrl);
        }
        else {
            window.parent?.postMessage('recorder-loaded', adminAppUrl);
        }
    }
}
