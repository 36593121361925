import { readonly, ref } from 'vue';

export function usePromisedModal<T>() {
    let resolveFn: (value?: T | Promise<T> | undefined) => void;
    let tabHandler: (event: KeyboardEvent) => void;
    let originElement: HTMLElement | undefined;

    const isVisible = ref(false);
    const body = document.querySelector('body');

    const openModal = (eventElement?: HTMLElement): Promise<T | undefined> => {
        isVisible.value = true;
        body?.classList.add('is-modalOpen');
        originElement = eventElement;
        setTimeout(() => {
            trapTabInModal();
        }, 1);

        return new Promise(resolve => {
            resolveFn = resolve;
        });
    };

    const response = (value?: T): void => {
        isVisible.value = false;
        body?.classList.remove('is-modalOpen');
        document.removeEventListener('keydown', tabHandler);
        resolveFn(value);

        if (originElement) {
            originElement.focus();
        }
    };

    const trapTabInModal = (): void => {
        const modalBody = document.getElementById('modalBody');
        if (!modalBody) {
            return;
        }

        const tabbableSelectors = 'button, [href], input, select, textarea, [tabindex="0"]';
        const tabbableElements = [...modalBody.querySelectorAll(tabbableSelectors)] as HTMLElement[];
        const buttonCloseModal = tabbableElements[0];
        const firstElementTabbable = tabbableElements[1];
        const lastElementTabbable = tabbableElements[tabbableElements.length - 1];

        if (firstElementTabbable) {
            firstElementTabbable.focus();
        }
        else {
            buttonCloseModal.focus();
        }

        tabHandler = (event: KeyboardEvent) => {
            if (event.key === 'Tab') {
                if (event.shiftKey && document.activeElement === buttonCloseModal) {
                    event.preventDefault();
                    lastElementTabbable.focus();
                }
                else if (!event.shiftKey && document.activeElement === lastElementTabbable) {
                    event.preventDefault();
                    buttonCloseModal.focus();
                }
            }
            else if (event.key === 'Escape') {
                buttonCloseModal.click();
            }
        };

        document.addEventListener('keydown', tabHandler);
    };

    return {
        isVisible: readonly(isVisible),
        openModal,
        response
    };
}
