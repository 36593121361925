import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-642b5bae"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "c-attribute"
}
const _hoisted_2 = { class: "c-attribute-title" }
const _hoisted_3 = {
  key: 0,
  class: "c-attribute-subtitle c-attribute-subtitle--optional"
}
const _hoisted_4 = { class: "u-marginBottomUI--small" }
const _hoisted_5 = { class: "c-attribute-subtitle" }
const _hoisted_6 = {
  key: 0,
  class: "c-attribute-errorMessage"
}
const _hoisted_7 = ["for"]
const _hoisted_8 = {
  key: 0,
  class: "c-attribute-subtitle c-attribute-subtitle--optional"
}
const _hoisted_9 = ["id"]
const _hoisted_10 = ["disabled"]
const _hoisted_11 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_tooltip = _resolveComponent("tooltip")!
  const _component_range_input = _resolveComponent("range-input")!
  const _component_centred_range = _resolveComponent("centred-range")!
  const _component_single_tag = _resolveComponent("single-tag")!
  const _component_multi_tag = _resolveComponent("multi-tag")!
  const _component_date_select = _resolveComponent("date-select")!
  const _component_location_select = _resolveComponent("location-select")!

  return (!_ctx.isEmptyZone)
    ? (_openBlock(), _createElementBlock("fieldset", _hoisted_1, [
        _createElementVNode("legend", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
          (_ctx.attribute.isOptional)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('(Optional)')), 1))
            : _createCommentVNode("", true),
          (_ctx.attribute.isPrivate)
            ? (_openBlock(), _createBlock(_component_tooltip, {
                key: 1,
                class: "c-attribute-tooltip",
                content: _ctx.$t('Your answer won\'t be shared publicly')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_icon, {
                    icon: "lock",
                    class: "c-icon--small c-icon--currentColor"
                  })
                ]),
                _: 1
              }, 8, ["content"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.subtitle), 1),
          (_ctx.hasError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t(_ctx.errorMessage)), 1))
            : _createCommentVNode("", true)
        ]),
        (_ctx.attribute.type === 'range')
          ? (_openBlock(), _createBlock(_component_range_input, {
              key: 0,
              modelValue: _ctx.inputModel,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputModel) = $event)),
              class: _normalizeClass({ hasError: _ctx.hasError }),
              minLabel: _ctx.attribute.minLabel,
              maxLabel: _ctx.attribute.maxLabel
            }, null, 8, ["modelValue", "class", "minLabel", "maxLabel"]))
          : _createCommentVNode("", true),
        (_ctx.attribute.type === 'centered-range')
          ? (_openBlock(), _createBlock(_component_centred_range, {
              key: 1,
              modelValue: _ctx.inputModel,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputModel) = $event)),
              class: _normalizeClass({ hasError: _ctx.hasError }),
              minLabel: _ctx.attribute.minLabel,
              midLabel: _ctx.attribute.midLabel,
              maxLabel: _ctx.attribute.maxLabel
            }, null, 8, ["modelValue", "class", "minLabel", "midLabel", "maxLabel"]))
          : _createCommentVNode("", true),
        (_ctx.attribute.type === 'single-value')
          ? (_openBlock(), _createBlock(_component_single_tag, {
              key: 2,
              modelValue: _ctx.inputModel,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.inputModel) = $event)),
              class: _normalizeClass({ hasError: _ctx.hasError }),
              options: _ctx.attribute.options,
              isOptional: _ctx.attribute.isOptional
            }, null, 8, ["modelValue", "class", "options", "isOptional"]))
          : _createCommentVNode("", true),
        (_ctx.attribute.type === 'multi-value')
          ? (_openBlock(), _createBlock(_component_multi_tag, {
              key: 3,
              modelValue: _ctx.inputModel,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.inputModel) = $event)),
              class: _normalizeClass({ hasError: _ctx.hasError }),
              options: _ctx.attribute.options
            }, null, 8, ["modelValue", "class", "options"]))
          : _createCommentVNode("", true),
        (_ctx.attribute.type === 'drop-down')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
              _createElementVNode("label", {
                for: _ctx.attribute.title,
                class: "u-assistive-hidden"
              }, [
                _createTextVNode(_toDisplayString(_ctx.attribute.title) + " ", 1),
                (_ctx.attribute.isOptional)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('(Optional)')), 1))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_7),
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.inputModel) = $event)),
                id: _ctx.attribute.title,
                class: _normalizeClass([{ hasError: _ctx.hasError }, "c-select"])
              }, [
                _createElementVNode("option", {
                  value: undefined,
                  disabled: !_ctx.attribute.isOptional
                }, _toDisplayString(_ctx.$t('Select an option')), 9, _hoisted_10),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropdownOptions, (option) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: option.value,
                    value: option.value
                  }, _toDisplayString(option.displayName), 9, _hoisted_11))
                }), 128))
              ], 10, _hoisted_9), [
                [_vModelSelect, _ctx.inputModel]
              ])
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.attribute.type === 'date-components')
          ? (_openBlock(), _createBlock(_component_date_select, {
              key: 5,
              modelValue: _ctx.inputModel,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.inputModel) = $event)),
              components: _ctx.attribute.components,
              class: _normalizeClass({ hasError: _ctx.hasError }),
              isOptional: _ctx.attribute.isOptional
            }, null, 8, ["modelValue", "components", "class", "isOptional"]))
          : _createCommentVNode("", true),
        (_ctx.attribute.type === 'location')
          ? (_openBlock(), _createBlock(_component_location_select, {
              key: 6,
              modelValue: _ctx.inputModel,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.inputModel) = $event)),
              components: _ctx.attribute.components,
              class: _normalizeClass({ hasError: _ctx.hasError }),
              isOptional: _ctx.attribute.isOptional
            }, null, 8, ["modelValue", "components", "class", "isOptional"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}