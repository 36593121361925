
import { Vue, Options } from 'vue-class-component';

import Icon from '@/shared-components/Icon.vue';
import store from '@/store';

@Options({
    components: {
        Icon
    }
})
export default class PoweredByOkendo extends Vue {
    get hideOkendoBranding(): boolean {
        const { settings } = store.state.settings;
        return settings?.hideOkendoBranding === true;
    }
}
