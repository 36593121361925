import WebFont from 'webfontloader';

import store from '@/store';
import { StyleSettings } from '@okendo/reviews-common';

export async function loadFont(): Promise<void> {
    return new Promise((resolve) => {
        WebFont.load({
            google: {
                families: [getFontName(store.state.settings.settings)]
            },
            fontloading: () => {
                resolve();
            }
        });
    });
}

export function getFontName(customStyling?: StyleSettings.RecorderPlus): string {
    return customStyling?.fontName || 'Lato';
}
