import { CouponValue, Review } from '@okendo/reviews-common';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export class RewardModule extends VuexModule {
    reward?: Review.AchievedReward;
    previouslyAchievedReward?: CouponValue;

    @Mutation
    UPDATE_REWARD({ achievedReward }: UpdateReward) {
        this.reward = achievedReward;
    }

    @Mutation
    UPDATE_PREVIOUS_REWARD({ previouslyAchievedReward }: UpdatePreviousReward) {
        this.previouslyAchievedReward = previouslyAchievedReward;
    }
}

export type RewardModuleMethod = UpdateReward | UpdatePreviousReward;

interface UpdateReward {
    type: 'reward/UPDATE_REWARD';
    achievedReward: Review.AchievedReward;
}

interface UpdatePreviousReward {
    type: 'reward/UPDATE_PREVIOUS_REWARD';
    previouslyAchievedReward?: CouponValue;
}
