import store from '@/store';

export function isReviewUpgradeable(media: MediaWithType[] | undefined): boolean {
    const { imageUrl } = store.state.profile.reviewerProfile;
    const { videoRewardText } = store.state.banner.reward;
    const { disableMediaCapture, disableSocialLogin, disableProfilePictureUpload } = store.state.settings.settings;

    // Is the review missing: a Profile Picture, media upload, or a potential Reward upgrade?
    const isMissingProfilePicture = !imageUrl && !(disableSocialLogin && disableProfilePictureUpload);
    const isMissingMedia = !media?.length && !disableMediaCapture;
    const isMissingVideoReward = !!videoRewardText && !media?.filter(mediaItem => mediaItem.type === 'video') && !disableMediaCapture;

    return isMissingProfilePicture || isMissingMedia || isMissingVideoReward;
}

interface MediaWithType {
    type: 'image' | 'video'
}
