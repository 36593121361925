
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        modelValue: String,
        options: Array,
        isOptional: Boolean
    },
    emits: {
        'update:modelValue': (v: string) => typeof v === 'string' || typeof v === 'undefined'
    }
})
export default class SingleTag extends Vue {
    attributeName = `singleTag-${Math.floor(Math.random() * 1000000)}`;
    modelValue?: string;
    isOptional?: boolean;

    updateModelValue(target: HTMLInputElement): void {
        const value = target.value;
        const isUnchecking = this.modelValue === value;
        if (!this.isOptional && isUnchecking) {
            target.checked = true;
            return;
        }

        const newModelValue = isUnchecking ? undefined : value;
        this.$emit('update:modelValue', newModelValue);
    }
}
