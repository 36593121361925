
import { Options, Vue } from 'vue-class-component';

import AvatarUploader from '@/shared-components/AvatarUploader.vue';
import Icon from '@/shared-components/Icon.vue';
import Modal, { BaseModalResponse } from '@/shared-components/Modal.vue';
import store from '@/store';
import { ReviewerProfile } from '@/store/modules/profile';
import { StoreMethod } from '@/store/storeTypings';
import { usePromisedModal } from '@/utils/usePromisedModal';
import { convertToDisplayName } from '@/utils/profileUtils';
import SocialLogin from './SocialLogin.vue';
import { isEmailAddress } from '@/utils/validationUtil';

@Options({
    props: {
        hasSubmitted: Boolean
    },
    components: {
        AvatarUploader,
        Icon,
        Modal,
        SocialLogin
    },
    store
})
export default class Profile extends Vue {
    modal = usePromisedModal<BaseModalResponse>();
    hasSubmitted!: boolean;
    isUploadingAvatar = false;

    get isReviewEnhancementPage(): boolean {
        return this.$route.name === 'Review Enhancement';
    }

    get isPreviouslyReviewed(): boolean {
        return store.state.product.isPreviouslyReviewed;
    }

    get namePlaceholderText(): string | undefined {
        return !this.profile.name && this.hasSubmitted
            ? this.$t('Please enter your name')
            : undefined;
    }

    get emailPlaceholderText(): string | undefined {
        return !this.profile.email && this.hasSubmitted
            ? this.$t('Please enter your email address')
            : undefined;
    }

    get isEmailAddress(): boolean {
        return isEmailAddress(this.profile.email);
    }

    get isSocialLoginEnabled(): boolean {
        const { settings } = store.state.settings;
        return !settings.disableSocialLogin;
    }

    get isLoggedIn(): boolean {
        return store.state.profile.isLoggedIn;
    }

    get profile(): ReviewerProfile {
        return store.state.profile.reviewerProfile;
    }

    get displayName(): string | undefined {
        const fullName = this.profile.name;
        return fullName ? convertToDisplayName(fullName) : undefined;
    }

    get isEmailInputVisible(): boolean {
        if (this.isReviewEnhancementPage) {
            return false;
        }

        const { reviewRequestId } = store.state.order;
        return !reviewRequestId || reviewRequestId === 'test';
    }

    get isNameInputVisible(): boolean {
        if (this.isReviewEnhancementPage) {
            return false;
        }

        return !store.state.review.reviewerName;
    }

    get isProfilePictureUploadEnabled(): boolean {
        const { settings } = store.state.settings;
        return !settings.disableProfilePictureUpload;
    }

    get isProfileDividerVisible(): boolean {
        const hasElementAbove = this.isSocialLoginEnabled;
        const hasElementBelow = this.isNameInputVisible || this.isEmailInputVisible || this.isProfilePictureUploadEnabled;

        return hasElementAbove && hasElementBelow;
    }

    get isAddProfileImageModalEnabled(): boolean {
        return this.isSocialLoginEnabled || this.isProfilePictureUploadEnabled;
    }

    get profileName(): string | undefined {
        return this.profile.name;
    }

    set profileName(value: string | undefined) {
        this.updateReviewerProfile({ name: value });
    }

    async openAddProfileImageModal(event: MouseEvent) {
        await this.modal.openModal(event.target as HTMLElement);
    }

    handleModalResponse(response: BaseModalResponse) {
        this.modal.response(response);
    }

    async logout(): Promise<void> {
        if (this.profile.socialMediaType === 'facebook') {
            await this.logoutFacebook();
        }
        else if (this.profile.socialMediaType === 'google') {
            const email = store.state.profile.reviewerProfile.email;
            if (email) {
                window.google?.accounts.id.revoke(email);
            }
        }

        this.clearProfile();
    }

    logoutFacebook(): Promise<void> {
        return new Promise(resolve => {
            window.FB.logout(() => {
                resolve();
            });
        });
    }

    clearProfile(): void {
        store.commit<StoreMethod>({
            type: 'profile/CLEAR_PROFILE'
        });
    }

    completedUpload(): void {
        if (this.modal.isVisible) {
            this.modal.response({ confirmation: true });
        }
    }

    updateReviewerProfile(reviewerProfile: ReviewerProfile): void {
        store.commit<StoreMethod>({
            type: 'profile/UPDATE_REVIEWER_PROFILE',
            reviewerProfile
        });
    }
}
