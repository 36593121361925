import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_banner = _resolveComponent("banner")!
  const _component_review_form = _resolveComponent("review-form")!
  const _component_view_state = _resolveComponent("view-state")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_banner),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.viewState === 'content')
        ? (_openBlock(), _createBlock(_component_review_form, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_view_state, {
        "view-state": _ctx.viewState,
        onRetry: _ctx.loadReviewData
      }, null, 8, ["view-state", "onRetry"])
    ])
  ], 64))
}