import { StyleSettings } from '@okendo/reviews-common';

import { getFontName } from './fontUtil';

export function enableBackgroundColor(): void {
    if (document.getElementById('background-color')) {
        return;
    }

    const backgroundColorElement = document.createElement('style');
    backgroundColorElement.id = 'background-color';
    backgroundColorElement.setAttribute('type', 'text/css');
    document.head.appendChild(backgroundColorElement);
    backgroundColorElement.innerHTML = `
        body, html { background-color: var(--recorder-app-background-color); }
        #app { background-color: var(--recorder-app-card-background-color); }`;
}

export function setRecorderStyling(customStyling?: StyleSettings.RecorderPlus): void {
    let customStyleElement = document.getElementById('custom-styling');
    if (!customStyleElement) {
        customStyleElement = document.createElement('style');
        customStyleElement.id = 'custom-styling';
        customStyleElement.setAttribute('type', 'text/css');
    }

    document.head.appendChild(customStyleElement);

    const colors: StyleSettings.RecorderPlus.Colors = {
        starColor: '#FFAF00',
        starEmptyColor: '#C7CCDB',
        primaryFontColor: '#384B57',
        secondaryFontColor: '#6B6D80',
        linkColor: '#0E7D81',
        linkHoverColor: '#0E7D81',
        buttonBackgroundColor: '#0E7D81',
        buttonBorderColor: '#0E7D81',
        buttonFontColor: '#FFFFFF',
        buttonHoverColor: '#09555A',
        buttonHoverBorderColor: '#09555A',
        buttonHoverFontColor: '#FFFFFF',
        componentFontColor: '#384B57',
        componentSecondaryFontColor: '#6B6D80',
        componentBackgroundColor: '#FAFAFB',
        componentHoverColor: '#F4F4F6',
        componentBorderColor: '#E5E5EC',
        componentIconColor: '#384B57',
        componentIconHoverColor: '#D3D4DD',
        componentIconBackgroundColor: '#E5E5EC',
        attributeFontColor: '#384B57',
        attributeBackgroundColor: '#FAFAFB',
        attributeHoverColor: '#F4F4F6',
        attributeBorderColor: '#E5E5EC',
        attributeActiveColor: '#0E7D81',
        attributeActiveFontColor: '#FFFFFF',
        attributeIconColor: '#DBDDE4',
        bannerFontColor: '#FFFFFF',
        bannerBackgroundColor: '#0E7D81',
        bannerIconColor: '#FFFFFF',
        bannerIconBackgroundColor: '#1D979B',
        progressColor: '#F4F4F6',
        progressCompleteStepColor: '#0E7D81',
        progressIncompleteStepColor: '#DBDDE4',
        couponCompleteColor: '#0E7D81',
        couponIncompleteColor: '#E5E5EC',
        appBackgroundColor: '#384B57',
        appCardBackgroundColor: '#FFFFFF',
        borderColor: '#DBDDE4',
        focusBorderColor: '#57BFBE',
        focusBorderShadowColor: '#CBF2F0',
        inputBorderColor: '#DBDDE4',
        bubbleColor: '#0E7D81',
        ...customStyling?.colors
    };

    const fontName = getFontName(customStyling);
    const stylingCSS = buildStylingCSS(colors);
    const hasBorderRadius = !(customStyling?.removeBorderRadius);
    const buttonTextTransform = customStyling?.button?.textTransform ?? 'none';
    const buttonBorderWidthValue = customStyling?.button?.borderWidth?.value ?? 0;
    const buttonBorderWidthUnit = customStyling?.button?.borderWidth?.unit ?? 'px';
    const buttonBorderWidth = `${buttonBorderWidthValue}${buttonBorderWidthUnit}`;
    const loyaltyCardBackgroundColor = !isDark(colors.primaryFontColor!) ? 'rgba(255,255,255,0.1)' : '#fafafb';

    customStyleElement.innerHTML = `
            body {
                font-family: '${fontName}', sans-serif;
                --recorder-border-radius: ${hasBorderRadius ? '4px' : '0'};
                --recorder-border-radius-small: ${hasBorderRadius ? '3px' : '0'};
                --recorder-border-radius-x-small: ${hasBorderRadius ? '2px' : '0'};
                --recorder-button-text-transform: ${buttonTextTransform};
                --recorder-button-border-width: ${buttonBorderWidth};
                --recorder-loyalty-card-background-color: ${loyaltyCardBackgroundColor};
                ${stylingCSS}
            }
            ${customStyling?.customCss ?? ''}`;
}

function buildStylingCSS(styling: StyleSettings.RecorderPlus.Colors): string {
    let stylingCSS = '';
    for (const style in styling) {
        const stylingKey = style as keyof StyleSettings.RecorderPlus.Colors;
        stylingCSS = `${stylingCSS}
            ${toCSSVarName(stylingKey)}: ${styling[stylingKey]};`;
    }
    return stylingCSS;
}

function toCSSVarName(stylingKey: string): string {
    const kebabCase = stylingKey.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    return `--recorder-${kebabCase}`;
}

export function isDark(color: string): boolean {
    const brightness = (r: number, g: number, b: number): number => (r * 299 + g * 587 + b * 114) / 1000;
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    const rgbColor =
        result?.[1] && result[2] && result[3]
            ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            }
            : null;

    if (!rgbColor) {
        return false;
    }

    return brightness(rgbColor.r, rgbColor.g, rgbColor.b) < 128;
}
