
import { Options, Vue } from 'vue-class-component';

import Icon from '@/shared-components/Icon.vue';

@Options({
    components: {
        Icon
    },
    props: {
        modelValue: String,
        minLabel: String,
        midLabel: String,
        maxLabel: String
    },
    emits: {
        'update:modelValue': (v: string) => typeof v === 'string'
    }
})
export default class CentredRange extends Vue {
    attributeName = `centredRange-${Math.floor(Math.random() * 1000000)}`;

    updateModelValue(value: string): void {
        const normalisedValue = (parseInt(value, 10) - 1) / 2 - 1;
        this.$emit('update:modelValue', normalisedValue.toString());
    }
}
