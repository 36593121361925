import store from '@/store';
import { StoreMethod } from '@/store/storeTypings';
import { getChannelSurveyConfiguration, getSurveySettings } from './api';

const surveysAppUrl = process.env.VUE_APP_SURVEYS_APP_URL;

export function loadSurveyScript(): void {
    const {
        order: { reviewRequestId },
        subscriber: { locale, subscriberId },
        survey: { channelSurveyId, channelSurvey, surveySettings }
    } = store.state;

    const { email: reviewerEmail } = store.state.profile.reviewerProfile;
    if (!channelSurveyId || !channelSurvey) {
        return;
    }

    window.surveyDetails = {
        subscriberId,
        channelSurveyId,
        channelSurvey,
        reviewerEmail,
        reviewRequestId,
        surveySettings,
        locale: locale.code,
        variant: locale.variant
    };

    const surveyScript = document.createElement('script');
    surveyScript.setAttribute('src', `${surveysAppUrl}/js/okendo-surveys.postreview.js`);
    document.head.appendChild(surveyScript);
}

export async function loadChannelSurvey(): Promise<void> {
    const {
        review: { review },
        product: { product },
        profile: { reviewerProfile },
        order: { reviewRequestId }
    } = store.state;

    if (!review?.rating || !reviewerProfile?.email) {
        return;
    }

    store.commit<StoreMethod>({
        type: 'survey/UPDATE_IS_LOADING_SURVEY',
        isLoadingSurvey: true
    });

    try {
        const { configuration } = await getChannelSurveyConfiguration({
            rating: review.rating,
            productId: product.productId,
            email: reviewerProfile.email,
            reviewRequestId
        });

        // Can only happen when endpoint is hit from on-site and not from recorder
        if ('displayItems' in configuration) {
            return;
        }

        // On success, or if no survey can be found
        store.commit<StoreMethod>({
            type: 'survey/UPDATE_CHANNEL_SURVEY',
            channelSurveyId: configuration.channelSurveyId,
            channelSurvey: configuration.channelSurvey
        });
    }
    catch {
        // Other errors
        store.commit<StoreMethod>({
            type: 'survey/UPDATE_IS_LOADING_SURVEY',
            isLoadingSurvey: false
        });
    }
}

export async function loadSurveySettings(): Promise<void> {
    try {
        const surveySettings = await getSurveySettings();

        store.commit<StoreMethod>({
            type: 'survey/UPDATE_SURVEY_SETTINGS',
            surveySettings
        });
    }
    catch {
        // Fallback is present in the surveys-capture repo
    }
}

export async function setupSurveyPreview(): Promise<void> {
    store.commit<StoreMethod>({
        type: 'survey/UPDATE_IS_LOADING_SURVEY',
        isLoadingSurvey: true
    });

    try {
        const { configuration } = await getChannelSurveyConfiguration();

        // Can only happen when endpoint is hit from on-site and not from recorder
        if ('displayItems' in configuration) {
            return;
        }

        store.commit<StoreMethod>({
            type: 'survey/UPDATE_CHANNEL_SURVEY',
            channelSurveyId: configuration.channelSurveyId,
            channelSurvey: configuration.channelSurvey
        });
    }
    catch {
        store.commit<StoreMethod>({
            type: 'survey/UPDATE_IS_LOADING_SURVEY',
            isLoadingSurvey: false
        });
    }
}
