
import { ReviewRequestResource } from '@okendo/reviews-common';
import { Options, Vue } from 'vue-class-component';
import { uuid } from 'vue-uuid';

import Icon from '@/shared-components/Icon.vue';
import store from '@/store';
import { formatDuration, getSecondsFromDuration, mediaCountLimit, sendMediaUploadRequest } from '@/utils/mediaUploadUtil';
import { isMobile } from '@/utils/deviceUtils';
import { postAnalytics } from '@/utils/api';
import { StoreMethod } from '@/store/storeTypings';
import { sendToRaygun } from '@/raygun';

@Options({
    components: {
        Icon
    },
    props: {
        modelValue: Array
    },
    emits: {
        'update:modelValue': (v: MediaBlock[]) => typeof v === 'object'
    },
    store
})
export default class MediaUploader extends Vue {
    readonly thumbnailWidth = 62;
    readonly minimumFizeSize = 125;

    modelValue?: MediaBlock[];
    isUploaderHighlighted = false;
    mediaUploadProgress = 0;
    uploadingMediaIds: string[] = [];

    get isPreviewMode(): boolean {
        return store.state.subscriber.previewMode;
    }

    get isMediaCountBelowLimit(): boolean {
        const mediaCount = this.modelValue?.length || 0;
        return mediaCount < mediaCountLimit();
    }

    get clickToUploadText(): string {
        return isMobile()
            ? this.$t('Click here to upload')
            : this.$t('Click here or drag to upload');
    }

    mediaDrag(): void {
        if (!this.isUploaderHighlighted) {
            this.isUploaderHighlighted = true;
        }
    }

    mediaDragLeave(): void {
        if (this.isUploaderHighlighted) {
            this.isUploaderHighlighted = false;
        }
    }

    mediaDrop(dragEvent: DragEvent): void {
        this.isUploaderHighlighted = false;
        if (!this.isPreviewMode) {
            this.onMediaFilesChange(dragEvent.dataTransfer?.files);
        }
    }

    addMedia(newMediaBlock: MediaBlock): void {
        postAnalytics({ eventName: 'change-media' });

        this.$emit('update:modelValue', this.modelValue?.concat([newMediaBlock]));
    }

    removeMedia(streamId: string) {
        const filteredMediaBlocks = this.modelValue?.filter(
            mediaBlock => mediaBlock.streamId !== streamId
        );

        this.uploadingMediaIds = this.uploadingMediaIds.filter(
            id => id !== streamId
        );

        if (!this.uploadingMediaIds.length) {
            this.resetIndicator();
        }

        this.$emit('update:modelValue', filteredMediaBlocks);
    }

    onMediaFilesChange(newFiles: FileList | undefined) {
        if (!newFiles) {
            return;
        }

        const newFilesArray = [...newFiles];
        const existingMediaCount = this.modelValue?.length || 0;
        const maximumNewFilesAllowed = mediaCountLimit() - existingMediaCount;
        if (newFilesArray.length >= maximumNewFilesAllowed) {
            const isOverLimit = newFilesArray.length > maximumNewFilesAllowed;
            store.dispatch<StoreMethod>({
                type: 'alert/SHOW',
                alertData: {
                    content: isOverLimit ?
                        this.$t('You have added more images and videos than are allowed, some have not been added') :
                        this.$t('You have reached the maximum number of images and videos which can be added'),
                    status: isOverLimit ? 'fail' : 'warning'
                }
            });

            newFilesArray.splice(maximumNewFilesAllowed);
        }

        const { subscriberId } = store.state.subscriber;
        for (const file of newFilesArray) {
            const type = file.type.includes('image') ? 'image' :
                file.type.includes('video') ? 'video' : undefined;

            if (!type) {
                store.dispatch<StoreMethod>({
                    type: 'alert/SHOW',
                    alertData: {
                        content: this.$t('Upload must be an image or video'),
                        status: 'fail'
                    }
                });
                continue;
            }

            const onMediaError = (errorMessage?: string) => {
                sendToRaygun(new Error(errorMessage ?? 'Media Upload Error'), {});
                store.dispatch<StoreMethod>({
                    type: 'alert/SHOW',
                    alertData: {
                        content: this.$t('Failed to upload media'),
                        status: 'fail'
                    }
                });

                this.removeMedia(streamId);
            };

            if (file.size < this.minimumFizeSize) {
                onMediaError(`File size is too small: ${file.size} bytes`);
                continue;
            }

            const streamId = uuid.v4();
            this.uploadingMediaIds.push(streamId);
            const handler = this.progressHandlerFactory(streamId);
            const mediaBlock: MediaBlock = {
                streamId,
                name: file.name,
                progress: 0,
                type
            };

            if (type === 'image') {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onerror = () => onMediaError();

                reader.onload = async () => {
                    if (reader.result) {
                        if (reader.result.toString().length < this.minimumFizeSize) {
                            onMediaError(`Image size is too small: ${reader.result.toString().length}`);
                            return;
                        }

                        mediaBlock.type = 'image';
                        mediaBlock.src = reader.result;
                        this.addMedia(mediaBlock);

                        const isSuccess = await sendMediaUploadRequest(subscriberId, mediaBlock, file, handler);
                        this.onMediaUploadComplete(streamId, isSuccess);
                    }
                };
            }
            else if (type === 'video') {
                const video = window.document.createElement('video');
                const src = URL.createObjectURL(file);
                video.preload = 'metadata';
                video.src = src;
                video.onerror = () => onMediaError();

                video.onloadedmetadata = async () => {
                    if (video.duration < 1) {
                        onMediaError(`Video is too short: ${video.duration} seconds`);
                        return;
                    }

                    mediaBlock.type = 'video';
                    mediaBlock.src = src;
                    mediaBlock.duration = formatDuration(video.duration);
                    this.addMedia(mediaBlock);

                    const isSuccess = await sendMediaUploadRequest(subscriberId, mediaBlock, file, handler);
                    this.onMediaUploadComplete(streamId, isSuccess);
                };
            }
        }
    }

    onMediaUploadComplete(streamId: string, isSuccess: boolean) {
        if (!isSuccess) {
            this.removeMedia(streamId);

            store.dispatch<StoreMethod>({
                type: 'alert/SHOW',
                alertData: {
                    content: this.$t('Failed to upload media'),
                    status: 'fail'
                }
            });
        }
    }

    progressHandlerFactory(streamId: string): ProgressHandler {
        return (progress: number) => {
            if (!this.modelValue) {
                return;
            }

            const updatedMediaBlocks = this.modelValue.reduce((acc, cur) => {
                if (cur.streamId === streamId) {
                    cur.progress = progress;
                }
                return [...acc, cur];
            }, [] as MediaBlock[]);

            this.mediaUploadProgress = updatedMediaBlocks
                .filter(media => this.uploadingMediaIds.includes(media.streamId))
                .reduce((acc, cur) => (acc + cur.progress), 0) / this.uploadingMediaIds.length;

            this.$emit('update:modelValue', updatedMediaBlocks);
        };
    }

    videoLoaded(event: Event, mediaBlock: MediaBlock): any {
        (event.target as HTMLVideoElement).pause();
        this.getThumbnail(event.target as HTMLVideoElement, mediaBlock);
    }

    getThumbnail(video: HTMLVideoElement, mediaBlock: MediaBlock): void {
        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');

        if (canvasContext && video) {
            canvas.height = Math.max(this.thumbnailWidth, this.thumbnailWidth * (video.videoHeight / video.videoWidth));
            canvas.width = Math.max(this.thumbnailWidth, this.thumbnailWidth * (video.videoWidth / video.videoHeight));
            canvasContext.drawImage(video, 0, 0, canvas.width, canvas.height);
            const dataUrl = canvas.toDataURL();

            const thumbnailMedia: ReviewRequestResource.GetReview.Media = {
                type: 'video',
                thumbnailUrl: dataUrl,
                fullSizeUrl: dataUrl,
                streamId: mediaBlock.streamId,
                durationSeconds: getSecondsFromDuration(mediaBlock.duration ?? '0:00')
            };

            store.commit<StoreMethod>({
                type: 'review/UPDATE_VIDEO_THUMBNAILS',
                videoThumbnails: [
                    ...store.state.review.videoThumbnails,
                    thumbnailMedia
                ]
            });
        }
    }

    indicatorTransitionEnded() {
        const animationPauseAfterUploadComplete = 200;
        if (this.mediaUploadProgress === 100) {
            setTimeout(() => {
                if (this.mediaUploadProgress === 100) {
                    this.resetIndicator();
                }
            }, animationPauseAfterUploadComplete);
        }
    }

    resetIndicator(): void {
        this.mediaUploadProgress = 0;
        this.uploadingMediaIds = [];
    }
}
