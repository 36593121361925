
import { Options, Vue } from 'vue-class-component';

import store from '@/store';
import Icon from '@/shared-components/Icon.vue';
import { StoreMethod } from '@/store/storeTypings';
import { getRewardCurrencyWithValue } from '@/utils/rewardUtils';

@Options({
    components: {
        Icon
    },
    store
})
export default class Banner extends Vue {
    rewards: DisplayReward[] = [];

    created() {
        if (store.state.banner.reward) {
            this.buildDisplayRewards();
        }
    }

    expandBanner(el: HTMLElement): void {
        el.style.height = el.scrollHeight + 'px';
    }

    minimizeBanner(el: HTMLElement): void {
        el.style.height = '0';
    }

    get isRewardsBannerExpanded(): boolean {
        return store.state.banner.isRewardsBannerExpanded;
    }

    get lowestReward(): DisplayReward | undefined {
        return this.rewards[0];
    }

    get upgradeRewards(): DisplayReward[] {
        return this.rewards.slice(1);
    }

    get isEnhancementPage(): boolean {
        return this.$route.name === 'Review Enhancement';
    }

    get additionalRequirement(): string {
        if (!this.lowestReward?.actionText) {
            return '';
        }

        return this.$t(`${this.isEnhancementPage ? 'with' : 'and add'} a {requirement}`, {
            requirement: this.$t(this.lowestReward.actionText)
        });
    }

    get actionCouponText(): string {
        return this.rewardType === 'coupon' ? this.$t('a coupon for') : '';
    }

    getActionSuffix(upgradeReward: DisplayReward): string {
        return upgradeReward.actionSuffix ?? '';
    }

    getRewardText(upgradeReward: DisplayReward): string {
        if (this.rewardType !== 'points') {
            return upgradeReward.rewardText;
        }

        return getRewardCurrencyWithValue(upgradeReward.rewardText);
    }

    get isCapReachable(): boolean {
        const { rewardLimitText, reviewRewardText, imageRewardText, videoRewardText, socialRewardText } = store.state.banner.reward;
        const cap = this.parseToIntOrZero(rewardLimitText);
        const reviewReward = this.parseToIntOrZero(reviewRewardText);
        const imageReward = this.isMediaCaptureEnabled ? this.parseToIntOrZero(imageRewardText) : 0;
        const videoReward = this.isMediaCaptureEnabled ? this.parseToIntOrZero(videoRewardText) : 0;
        const socialReward = this.isSocialMediaEnabled || this.isProfilePictureUploadEnabled ? this.parseToIntOrZero(socialRewardText) : 0;
        return reviewReward + socialReward + imageReward + videoReward > cap;
    }

    get hasAchievableUpgradeRewards(): boolean {
        return this.upgradeRewards.length > 0;
    }

    get isMediaCaptureEnabled(): boolean {
        const { settings } = store.state.settings;
        return !settings.disableMediaCapture;
    }

    get isSocialMediaEnabled(): boolean {
        const { settings } = store.state.settings;
        return !settings.disableSocialLogin;
    }

    get isProfilePictureUploadEnabled(): boolean {
        const { settings } = store.state.settings;
        return !settings.disableProfilePictureUpload;
    }

    get upgradeRewardType(): string {
        return this.rewardType === 'coupon' ? 'Upgrade your coupon' : 'Earn additional points';
    }

    get upgradeRewardButtonAriaLabel(): string {
        return this.isRewardsBannerExpanded
            ? this.$t('Collapse upgrade rewards')
            : this.$t('Expand upgrade rewards');
    }

    get rewardType(): string | undefined {
        return store.state.banner.reward.type;
    }

    get rewardLimitDisclaimer(): string {
        const { alreadyEligible, rewardLimitText } = store.state.banner.reward;
        if (!(this.rewardType === 'points' && rewardLimitText)) {
            return '';
        }

        const eligibleForMoreText = alreadyEligible ? ' more' : '';
        return this.$t(`Up to {rewardLimitText}${eligibleForMoreText} points can be earned for this review`, {
            rewardLimitText
        });
    }

    toggleRewardsAccordion() {
        store.commit<StoreMethod>({
            type: 'banner/UPDATE_IS_BANNER_EXPANDED',
            isRewardsBannerExpanded: !this.isRewardsBannerExpanded
        });
    }

    buildDisplayRewards() {
        const { reviewRewardText, socialRewardText, imageRewardText, videoRewardText, imageActionSuffix, videoActionSuffix } = store.state.banner.reward;
        this.buildRewardDescription([
            { actionText: '', rewardText: reviewRewardText, isEnabled: true },
            { actionText: 'profile picture', rewardText: socialRewardText, isEnabled: this.isSocialMediaEnabled || this.isProfilePictureUploadEnabled },
            { actionText: 'photo', rewardText: imageRewardText, actionSuffix: imageActionSuffix, isEnabled: this.isMediaCaptureEnabled },
            { actionText: 'video', rewardText: videoRewardText, actionSuffix: videoActionSuffix, isEnabled: this.isMediaCaptureEnabled }
        ]);
    }

    buildRewardDescription(rewardByAction: RewardByAction[]) {
        rewardByAction.forEach(({ rewardText, actionText, actionSuffix, isEnabled }: RewardByAction) => {
            if (rewardText && isEnabled) {
                const reward = { actionText, rewardText, actionSuffix };
                this.rewards.push(reward);
            }
        });
    }

    parseToIntOrZero(rewardText: string | undefined): number {
        return rewardText ? parseInt(rewardText, 10) : 0;
    }
}

interface DisplayReward {
    actionText: ActionText;
    rewardText: string;
    actionSuffix?: string;
}

interface RewardByAction {
    actionText: ActionText;
    rewardText?: string;
    actionSuffix?: string;
    isEnabled: boolean;
}

// Note: at the moment, these three elements are of feminine gender in French;
// if an element of masculine gender is added to this list, then translations
// `ajoutez une <ActionText>` and  `avec une <ActionText>` will be broken
type ActionText = 'photo' | 'video' | 'profile picture' | '';

