import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export class ReviewFormModule extends VuexModule {
    rating = '0';
    body = '';
    title = '';

    @Mutation
    UPDATE_RATING({ rating }: UpdateRating) {
        this.rating = rating;
    }

    @Mutation
    UPDATE_BODY({ body }: UpdateBody) {
        this.body = body;
    }

    @Mutation
    UPDATE_TITLE({ title }: UpdateTitle) {
        this.title = title;
    }

    @Mutation
    START_NEW_REVIEW({ rating }: StartNewReview) {
        this.rating = rating;
        this.body = '';
        this.title = '';
    }
}

export type ReviewFormModuleMethod = UpdateRating
    | UpdateBody
    | UpdateTitle
    | StartNewReview;

type ModulePrefix = 'reviewForm';

interface UpdateRating {
    type: `${ModulePrefix}/UPDATE_RATING`;
    rating: string;
}

interface UpdateBody {
    type: `${ModulePrefix}/UPDATE_BODY`;
    body: string;
}

interface UpdateTitle {
    type: `${ModulePrefix}/UPDATE_TITLE`;
    title: string;
}

interface StartNewReview {
    type: `${ModulePrefix}/START_NEW_REVIEW`;
    rating: string;
}

export type SocialMediaType = '' | 'facebook' | 'google';
