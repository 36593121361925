import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ProductListViewPublic } from '@okendo/reviews-common';

@Module({ namespaced: true })
export class ShopMoreProductsModule extends VuexModule {
    products?: ProductListViewPublic[];
    isLoaded = false;

    @Mutation
    UPDATE_PRODUCTS({ products }: UpdateProducts) {
        this.products = products;
        this.isLoaded = true;
    }
}

export type ShopMoreProductsModuleMethod = UpdateProducts;

type ModulePrefix = 'shopMoreProducts';

interface UpdateProducts {
    type: `${ModulePrefix}/UPDATE_PRODUCTS`;
    products: ProductListViewPublic[];
}
