import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Review from '../views/review/Review.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Review',
        component: Review
    },
    {
        path: '/post-review',
        name: 'Post Review',
        component: () => import(
            /* webpackChunkName: "postReview" */ '../views/post-response/PostReview.vue'
        )
    },
    {
        path: '/review-enhancement',
        name: 'Review Enhancement',
        component: () =>
            import(
                /* webpackChunkName: "reviewEnhancement" */ '../views/review-enhancement/ReviewEnhancement.vue'
            )
    },
    {
        path: '/thanks',
        name: 'Thanks',
        component: () => import(
            /* webpackChunkName: "emailThanks" */ '../views/email-thanks/EmailThanks.vue'
        )
    },
    {
        path: '/question',
        name: 'Question',
        component: () => import(
            /* webpackChunkName: "question" */ '../views/question/Question.vue'
        ),
        alias: '/questions'
    },
    {
        path: '/post-question',
        name: 'Post Question',
        component: () => import(
            /* webpackChunkName: "postQuestion" */ '../views/post-response/PostQuestion.vue'
        )
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Review',
        component: Review
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
