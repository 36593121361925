import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/success-dark.svg'
import _imports_1 from '@/assets/icons/fail-dark.svg'
import _imports_2 from '@/assets/icons/warning-dark.svg'
import _imports_3 from '@/assets/icons/info-dark.svg'


const _withScopeId = n => (_pushScopeId("data-v-51049e31"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-bubble-icon" }
const _hoisted_2 = ["alt"]
const _hoisted_3 = ["alt"]
const _hoisted_4 = ["alt"]
const _hoisted_5 = ["alt"]
const _hoisted_6 = { class: "c-bubble-content" }
const _hoisted_7 = { class: "c-bubble-content-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["c-bubble", [_ctx.okeClass, {
            'c-bubble--positive': _ctx.status === 'success',
            'c-bubble--negative': _ctx.status === 'fail',
            'c-bubble--warning': _ctx.status === 'warning',
            'c-bubble--info': _ctx.status === 'info'
        }]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.status === 'success')
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            alt: _ctx.translatedStatus,
            src: _imports_0,
            class: "c-status-icon"
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.status === 'fail')
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            alt: _ctx.translatedStatus,
            src: _imports_1,
            class: "c-status-icon"
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.status === 'warning')
        ? (_openBlock(), _createElementBlock("img", {
            key: 2,
            alt: _ctx.translatedStatus,
            src: _imports_2,
            class: "c-status-icon"
          }, null, 8, _hoisted_4))
        : _createCommentVNode("", true),
      (_ctx.status === 'info')
        ? (_openBlock(), _createElementBlock("img", {
            key: 3,
            alt: _ctx.translatedStatus,
            src: _imports_3,
            class: "c-status-icon"
          }, null, 8, _hoisted_5))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.content), 1)
    ])
  ], 2))
}