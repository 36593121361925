import { MediaToTranscode, ReviewsAPIPublic, RewardCentre } from '@okendo/reviews-common';

import store from '@/store';
import { StoreMethod } from '@/store/storeTypings';
import { getRewardPrompt } from './api';
import i18n from '@/i18n';

function getRewardCurrency(): string {
    return store.state.banner.reward?.typeNoun || i18n.global.t('loyalty point');
}

export function getRewardCurrencyPlural(): string {
    return store.state.banner.reward?.typeNounPlural || i18n.global.t('loyalty points');
}

export function getRewardCurrencyWithValue(value: number | string): string {
    const isPlural = value !== 1 && value !== '1';
    return `${value.toLocaleString()} ${isPlural ? getRewardCurrencyPlural() : getRewardCurrency()}`;
}

export async function updateRewardPrompt(mediaToTranscode?: MediaToTranscode[]): Promise<void> {
    const { review } = store.state.review;
    let assessableReviewDetails: RewardCentre.RewardPrompt.AssessableReviewDetails | undefined;

    if (review) {
        const { body, reviewer: { customAvatar, socialConnection } } = review;
        const mediaToCount = mediaToTranscode ?? review.media ?? [];

        let imageCount = 0;
        let videoCount = 0;
        for (const media of mediaToCount) {
            media.type === 'image' ? imageCount++ : videoCount++;
        }

        assessableReviewDetails = {
            hasReviewText: !!body,
            imageCount,
            socialLogin: !!customAvatar || !!socialConnection,
            videoCount
        };
    }

    await setRewardPrompt(assessableReviewDetails);
}

async function setRewardPrompt(assessableReviewDetails: RewardCentre.RewardPrompt.AssessableReviewDetails | undefined): Promise<void> {
    const { reviewRequestId } = store.state.order;

    if (!reviewRequestId) {
        return;
    }

    const defaultAssessableReviewDetails: RewardCentre.RewardPrompt.AssessableReviewDetails = {
        hasReviewText: false,
        imageCount: 0,
        socialLogin: false,
        videoCount: 0
    };

    const requestBody: ReviewsAPIPublic.ReviewRequest.ReviewRequestId.RewardPrompt.Post.Request = {
        assessableReviewDetails: assessableReviewDetails ?? defaultAssessableReviewDetails,
        elementId: 'initial request',
        step: 'any'
    };

    const rewardPrompt = await getRewardPrompt(reviewRequestId, requestBody);
    store.commit<StoreMethod>({
        type: 'banner/UPDATE_ACHIEVABLE_REWARD',
        reward: rewardPrompt
    });
}
