import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export class SiteReviewModule extends VuexModule {
    title = '';
    description = '';
    siteRating = '0';
    hasSiteReview = false;
    submittedSuccessfully = false;

    @Mutation
    UPDATE_TITLE({ title }: UpdateTitle) {
        this.title = title;
    }

    @Mutation
    UPDATE_DESCRIPTION({ description }: UpdateDescription) {
        this.description = description;
    }

    @Mutation
    UPDATE_SITE_RATING({ siteRating }: UpdateSiteRating) {
        this.siteRating = siteRating;
    }

    @Mutation
    UPDATE_HAS_SITE_REVIEW({ hasSiteReview }: UpdateHasSiteReview) {
        this.hasSiteReview = hasSiteReview;
    }

    @Mutation
    SUBMITTED_SUCCESSFULLY() {
        this.submittedSuccessfully = true;
    }

    @Mutation
    RESET_FORM() {
        this.title = '';
        this.description = '';
        this.siteRating = '0';
    }
}

export type SiteReviewModuleMethod = UpdateTitle
    | UpdateDescription
    | UpdateSiteRating
    | ResetForm
    | UpdateHasSiteReview
    | SubmittedSuccessfully;

type ModulePrefix = 'siteReview';

interface UpdateTitle {
    type: `${ModulePrefix}/UPDATE_TITLE`;
    title: string;
}

interface UpdateDescription {
    type: `${ModulePrefix}/UPDATE_DESCRIPTION`;
    description: string;
}

interface UpdateSiteRating {
    type: `${ModulePrefix}/UPDATE_SITE_RATING`;
    siteRating: string;
}

interface ResetForm {
    type: `${ModulePrefix}/RESET_FORM`;
}

interface UpdateHasSiteReview {
    type: `${ModulePrefix}/UPDATE_HAS_SITE_REVIEW`;
    hasSiteReview: boolean;
}

interface SubmittedSuccessfully {
    type: `${ModulePrefix}/SUBMITTED_SUCCESSFULLY`;
}
